import { Box, IconButton, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ReplayIcon from '@mui/icons-material/Replay';
import { Stack } from '@mui/system';
import { useState, useEffect } from 'react';
import { useContext } from 'react';
import { AppContext } from 'store/AppProvider';
import DeleteModal from 'components/DeleteModal/DeleteModal';
import { toast } from 'react-toastify';
import { addTuning, deleteTuning, updateTuning } from 'api/tunigsAPI';
import TuningModal from './TuningModal';

const Tunings = ({ selectedTuningId, onSelect, onInfo, info }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openTuningModal, setOpenTuningModal] = useState(false);
  const [search, setSearch] = useState('');
  const { tunings, setReloadTunings } = useContext(AppContext);

  const canEdit = !!selectedTuningId;
  const filteredTunings = tunings.filter((tuning) => tuning.name.toLowerCase().includes(search));

  const handleDisplayAdd = () => {
    setIsEdit(false);
    setOpenTuningModal(true);
  };

  const handleDisplayDelete = () => {
    if (!canEdit) return;
    setOpenDeleteModal(true);
  };

  const handleDisplayEdit = () => {
    if (!canEdit) return;
    setIsEdit(true);
    setOpenTuningModal(true);
  };

  const handleCloseModal = () => {
    setOpenTuningModal(false);
  };

  useEffect(() => {
    if (!selectedTuningId) onSelect(tunings[0]?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tunings]);

  useEffect(() => {
    const [tuning] = tunings.filter((item) => item.id === selectedTuningId);
    onInfo(tuning);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTuningId, tunings]);

  const handleDeleteTuning = async (id) => {
    try {
      await deleteTuning(id);
      toast.success('Tuning deleted');
      onSelect('');
      setReloadTunings(true);
    } catch (error) {
      console.error(error);
      toast.error('Cannot delete tuning');
    }
    setOpenDeleteModal(false);
  };

  const handleAddTuning = async (data) => {
    try {
      await addTuning(data);
      toast.success('Tuning added');
      setReloadTunings(true);
    } catch (error) {
      console.error(error);
      toast.error('Cannot add tuning');
    }
    setOpenTuningModal(false);
  };

  const handleEditTuning = async (data) => {
    try {
      await updateTuning(data);
      toast.success('Tuning updated');
      setReloadTunings(true);
    } catch (error) {
      console.error(error);
      toast.error('Cannot edit tuning');
    }
    setOpenTuningModal(false);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <Typography sx={{ color: 'text.primary' }}>{`Tunings (${tunings.length})`}</Typography>

        <IconButton onClick={handleDisplayAdd}>
          <AddIcon />
        </IconButton>

        <IconButton onClick={handleDisplayEdit} disabled={!canEdit}>
          <EditIcon />
        </IconButton>

        <IconButton onClick={handleDisplayDelete} disabled={!canEdit}>
          <DeleteIcon />
        </IconButton>
      </Box>

      <TextField
        fullWidth
        placeholder="Search tuning"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setSearch('');
                }}
                edge="end"
              >
                <ReplayIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />

      <Stack
        sx={{
          overflowY: 'auto',
          height: '100px',
          flex: '1 1 auto',
          border: '1px solid',
          borderColor: 'divider',
        }}
      >
        {filteredTunings.map((tuning) => (
          <MenuItem
            key={tuning.name}
            value={tuning.id}
            sx={{
              backgroundColor: tuning.id === selectedTuningId ? 'green' : '',
            }}
            onClick={() => onSelect(tuning.id)}
          >
            {tuning.name}
          </MenuItem>
        ))}
      </Stack>

      <TuningModal
        open={openTuningModal}
        onClose={handleCloseModal}
        title={isEdit ? 'Edit tuning' : 'Add tuning'}
        submit={isEdit ? handleEditTuning : handleAddTuning}
        isEdit={isEdit}
        tuning={isEdit ? info : null}
      />

      <DeleteModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title="Delete tuning"
        submit={handleDeleteTuning}
        id={info?.id}
        name={info?.name}
      />
    </>
  );
};

export default Tunings;
