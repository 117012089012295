import { Box, Button } from '@mui/material';
import ChromaticNotes from 'components/ChromaticNotes/ChromaticNotes';
import CustomModal from 'components/CustomModal/CustomModal';
import ChordsList from 'components/ChordsList/ChordsList';
import { useContext } from 'react';
import { useState } from 'react';
import { ChordsContext } from 'store/ChordsProvider';

const AddChordModal = ({ open, onClose, title, submit, sectionId }) => {
  const [selectedChordRoot, setSelectedChordRoot] = useState('C');
  const { selectedChordId, setSelectedChordId, chordInfo } = useContext(ChordsContext);

  const handleRootChange = (_, newNote) => {
    if (newNote !== null) setSelectedChordRoot(newNote);
  };

  const handleSubmit = () => {
    submit(selectedChordId, selectedChordRoot, sectionId);
    onClose();
  };

  const handleSelectChord = (chordId) => {
    setSelectedChordId(chordId);
  };

  return (
    <CustomModal open={open} onClose={onClose} title={title}>
      <Box sx={{ width: '350px' }}>
        <ChromaticNotes
          title="Root note"
          selectedRoot={selectedChordRoot}
          onChange={handleRootChange}
        />

        <ChordsList
          selectedChordId={selectedChordId}
          setSelectedChordId={setSelectedChordId}
          handleSelectChord={handleSelectChord}
          chordInfo={chordInfo}
        />

        <Box
          sx={{
            display: 'flex',
            gap: '0.5rem',
            justifyContent: 'flex-end',
            marginTop: '1rem',
          }}
        >
          <Button variant="outlined" onClick={handleSubmit}>
            Add
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default AddChordModal;
