import { Typography } from '@mui/material';

const ChordInfo = ({ chordInfo, root }) => {
  return (
    <Typography
      variant="h4"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        left: '35px',
      }}
    >
      {root} {chordInfo.shortName}
    </Typography>
  );
};

export default ChordInfo;
