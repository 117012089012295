import { useContext, useEffect } from 'react';
import ChromaticNotes from 'components/ChromaticNotes/ChromaticNotes';
import LeftNavBar from 'components/LeftNavBar/LeftNavBar';
import Page from 'components/Page/Page';
import Tunings from 'components/Tunings/Tunings';
import { ScalesContext } from 'store/ScalesProvider';
import ScalesList from '../../components/ScaleList/ScalesList';
import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getScale } from 'api/scalesAPI';

const Scales = () => {
  const {
    selectedScaleRoot,
    handleScaleRootChange,
    selectedScaleTuningId,
    setSelectedScaleTuningId,
    setScaleTuningInfo,
    scaleTuningInfo,
    setScaleInfo,
    selectedScaleId,
    setActiveScaleKeys,
    setScaleTransposedPattern,
    setSelectedScaleRoot,
    setSelectedScaleId,
    setTotalFrets,
    totalFrets,
    setStartFretDisplay,
    startFretDisplay,
    endFretDisplay,
    setEndFretDisplay,
    accidental,
    setAccidental,
  } = useContext(ScalesContext);

  const isShowScale = !!selectedScaleId && !!selectedScaleRoot;

  const navigate = useNavigate();
  const { root, scaleId } = useParams();
  const [searchParams] = useSearchParams();

  const createURL = (options) => {
    const {
      selectedScaleRoot,
      selectedScaleId,
      selectedScaleTuningId,
      totalFrets,
      startFretDisplay,
      endFretDisplay,
      accidental,
    } = options;

    const url = `/scales/${selectedScaleRoot}/${selectedScaleId}?tuningId=${selectedScaleTuningId}&totalFrets=${totalFrets}&startFret=${startFretDisplay}&endFret=${endFretDisplay}&accidental=${accidental}`;
    return url;
  };

  useEffect(() => {
    if (!selectedScaleId || !selectedScaleRoot) return;

    const fetch = async () => {
      try {
        const params = {
          root: selectedScaleRoot,
          scaleId: selectedScaleId,
          tuningId: selectedScaleTuningId,
        };

        const { data } = await getScale(params);
        const { scale, notes, scaleTransposedPattern } = data;

        setScaleInfo(scale);
        setActiveScaleKeys(notes);
        setScaleTransposedPattern(scaleTransposedPattern);
      } catch (error) {
        console.error(error);
        toast.error('Cannot get scale');
      }
    };

    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScaleRoot, selectedScaleId, selectedScaleTuningId]);

  useEffect(() => {
    if (!selectedScaleId || !selectedScaleRoot) return;

    const urlOptions = {
      selectedScaleRoot,
      selectedScaleId,
      selectedScaleTuningId,
      totalFrets,
      startFretDisplay,
      endFretDisplay,
      accidental,
    };
    const url = createURL(urlOptions);
    navigate(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedScaleRoot,
    selectedScaleId,
    selectedScaleTuningId,
    totalFrets,
    startFretDisplay,
    endFretDisplay,
    accidental,
  ]);

  useEffect(() => {
    setSelectedScaleRoot(root);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [root]);

  useEffect(() => {
    setSelectedScaleId(Number(scaleId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scaleId]);

  useEffect(() => {
    const tuningId = searchParams.get('tuningId');
    const totalFrets = searchParams.get('totalFrets');
    const startFret = searchParams.get('startFret');
    const endFret = searchParams.get('endFret');
    const accidental = searchParams.get('accidental');

    if (tuningId) setSelectedScaleTuningId(Number(tuningId));
    if (totalFrets) setTotalFrets(Number(totalFrets));
    if (startFret) setStartFretDisplay(Number(startFret));
    if (endFret) setEndFretDisplay(Number(endFret));
    if (accidental) setAccidental(accidental);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <Page>
      <LeftNavBar>
        <ChromaticNotes
          title="Root note"
          selectedRoot={selectedScaleRoot}
          onChange={handleScaleRootChange}
        />
        <ScalesList />
        <Tunings
          selectedTuningId={selectedScaleTuningId}
          onSelect={setSelectedScaleTuningId}
          onInfo={setScaleTuningInfo}
          info={scaleTuningInfo}
        />
      </LeftNavBar>

      {isShowScale && <Outlet />}
    </Page>
  );
};

export default Scales;
