import { useState, createContext } from 'react';

export const ScalesContext = createContext();

export const ScalesProvider = ({ children }) => {
  const [activeScaleKeys, setActiveScaleKeys] = useState({});
  const [scaleTuningInfo, setScaleTuningInfo] = useState({});
  const [scaleInfo, setScaleInfo] = useState({});
  const [selectedScaleRoot, setSelectedScaleRoot] = useState('C');
  const [selectedScaleTuningId, setSelectedScaleTuningId] = useState('');
  const [selectedScaleId, setSelectedScaleId] = useState('');
  const [scaleTransposedPattern, setScaleTransposedPattern] = useState([]);
  const [totalFrets, setTotalFrets] = useState(24);
  const [startFretDisplay, setStartFretDisplay] = useState(0);
  const [endFretDisplay, setEndFretDisplay] = useState(24);
  const [accidental, setAccidental] = useState('sharp');

  const handleSelectScale = (scaleId) => {
    setSelectedScaleId(scaleId);
  };

  const handleScaleRootChange = (_, newNote) => {
    if (newNote !== null) setSelectedScaleRoot(newNote);
  };

  const defaultValue = {
    activeScaleKeys,
    setActiveScaleKeys,
    scaleTuningInfo,
    setScaleTuningInfo,
    scaleInfo,
    setScaleInfo,
    selectedScaleRoot,
    setSelectedScaleRoot,
    selectedScaleTuningId,
    setSelectedScaleTuningId,
    selectedScaleId,
    setSelectedScaleId,
    handleSelectScale,
    handleScaleRootChange,
    scaleTransposedPattern,
    setScaleTransposedPattern,
    totalFrets,
    setTotalFrets,
    startFretDisplay,
    setStartFretDisplay,
    endFretDisplay,
    setEndFretDisplay,
    accidental,
    setAccidental,
  };

  return <ScalesContext.Provider value={defaultValue}>{children}</ScalesContext.Provider>;
};
