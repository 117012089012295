import { useState, useContext } from 'react';
import ChromaticNotes from 'components/ChromaticNotes/ChromaticNotes';
import LeftNavBar from 'components/LeftNavBar/LeftNavBar';
import Page from 'components/Page/Page';
import { Button, MenuItem, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { toast } from 'react-toastify';
import { ProgressionScalesContext } from 'store/ProgressionScalesProvider';
import { getChordProgression } from 'api/ApiChordProgression';
import DisplayChordProgression from './DisplayChordProgression';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ProgressionScalesList from 'components/ProgressionScalesList/ProgressionScalesList';

const ChordProgression = () => {
  const [selectedProgressionRoot, setSelectedProgressionRoot] = useState('C');
  const [numberOfChords, setNumberOfChords] = useState(4);
  const [selectedProgressionType, setSelectedProgressionType] = useState('random');
  const [startWithRoot, setStartWithRoot] = useState(true);
  const { chordProgression, setChordProgression, selectedProgressionScaleId } =
    useContext(ProgressionScalesContext);

  const handleRootChange = (_, newNote) => {
    if (newNote !== null) setSelectedProgressionRoot(newNote);
  };

  const handleNumberOfChordsChange = (event) => {
    setNumberOfChords(event.target.value);
  };

  const handleProgressionTypeChange = (event) => {
    setSelectedProgressionType(event.target.value);
  };

  const handleGetChordProgressionClick = async () => {
    try {
      const params = {
        root: selectedProgressionRoot,
        scaleId: selectedProgressionScaleId,
        numberOfChords,
        progressionType: selectedProgressionType,
        startWithRoot,
      };

      const { data } = await getChordProgression(params);
      setChordProgression(data);
    } catch (error) {
      console.error(error);
      toast.error('Cannot get chord progression');
    }
  };

  const isButtonDisabled = !selectedProgressionRoot || !selectedProgressionScaleId;

  return (
    <Page>
      <LeftNavBar>
        <ChromaticNotes
          title="Root note"
          selectedRoot={selectedProgressionRoot}
          onChange={handleRootChange}
        />
        <TextField
          type="number"
          label="Number of Chords"
          inputProps={{ min: 0, max: 24 }}
          value={numberOfChords}
          onChange={handleNumberOfChordsChange}
          sx={{ marginTop: '1rem', width: '50%' }}
        />

        <TextField
          label="Progression Type"
          select
          value={selectedProgressionType}
          onChange={handleProgressionTypeChange}
          sx={{ marginTop: '1rem' }}
        >
          <MenuItem value={'random'}>Random</MenuItem>
        </TextField>

        <ProgressionScalesList />
      </LeftNavBar>
      <Box sx={{ paddingTop: '1rem' }}>
        <Button
          onClick={handleGetChordProgressionClick}
          variant="outlined"
          disabled={isButtonDisabled}
        >
          Get Progression
        </Button>

        <DndProvider backend={HTML5Backend}>
          {chordProgression && (
            <DisplayChordProgression
              data={chordProgression}
              setStartWithRoot={setStartWithRoot}
              startWithRoot={startWithRoot}
            />
          )}
        </DndProvider>
      </Box>
    </Page>
  );
};

export default ChordProgression;
