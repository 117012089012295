import ApiClient from './ApiClient';

export const getChordProgression = (params) => ApiClient.get('/chordProgression', { params });

export const getProgressionChord = (params) => ApiClient.get('/chordProgression/chord', { params });

export const getProgressionScales = () => ApiClient.get('/chordProgression/scales');

export const addProgressionScale = (payload) => ApiClient.post('/chordProgression/scales', payload);

export const updateProgressionScale = (payload) =>
  ApiClient.put('/chordProgression/scales', payload);

export const deleteProgressionScale = (id) =>
  ApiClient.delete('/chordProgression/scales', { data: { id } });
