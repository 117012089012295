import { Button, Box, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { login } from 'api/auth';

const Login = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Field required'),
    password: Yup.string().required('Field required'),
  });
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const onSubmit = async ({ email, password }) => {
    try {
      const { data } = await login({ email, password });

      const { token, username, lastLogin } = data;

      localStorage.setItem('token', token);
      localStorage.setItem('username', username);
      localStorage.setItem('lastLogin', lastLogin);

      navigate('/chords', { replace: true });
    } catch (error) {
      console.error(error);
      toast.error('Cannot login');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        gap: '1rem',
      }}
    >
      <Typography variant="h4">Kompose</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '350px',
            gap: '1rem',
          }}
        >
          <Controller
            name="email"
            control={control}
            defaultValue=""
            id="email"
            render={({ field: { onChange, value, name } }) => (
              <TextField
                label="Email"
                required
                fullWidth
                name={name}
                value={value}
                onChange={onChange}
                error={!!errors[name]}
                helperText={errors[name]?.message}
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value, name } }) => (
              <TextField
                label="Password"
                type="password"
                required
                fullWidth
                name={name}
                value={value}
                onChange={onChange}
                error={!!errors[name]}
                helperText={errors[name]?.message}
              />
            )}
          />

          <Button fullWidth variant="outlined" type="submit" disabled={!isValid}>
            Login
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default Login;
