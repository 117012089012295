import { BLACK_KEY_WIDTH, BLACK_KEY_HEIGHT_PERCENT } from 'common/constants';
import { Button, Typography } from '@mui/material';

const BlackKey = ({ note, left, isActive, onClick }) => {
  const [sharp, flat] = note.split('-');
  const handleClick = () => onClick(sharp);

  return (
    <Button
      onClick={handleClick}
      sx={{
        position: 'absolute',
        display: 'flex',
        flexWrap: 'wrap',
        backgroundColor: isActive ? 'green' : 'black',
        height: `${BLACK_KEY_HEIGHT_PERCENT}%`,
        width: `${BLACK_KEY_WIDTH}px`,
        minWidth: `${BLACK_KEY_WIDTH}px`,
        left,
        zIndex: 1,
        border: '1px solid rgba(64,64,64,0.6)',

        '&:hover': {
          backgroundColor: 'gray',
        },
      }}
    >
      <Typography sx={{ textTransform: 'none' }}>{sharp}</Typography>
      <Typography sx={{ textTransform: 'none' }}>{flat}</Typography>
    </Button>
  );
};

export default BlackKey;
