import { Box, Button, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { filetypes } from 'common/constants';
import { useState } from 'react';

const { default: CustomModal } = require('components/CustomModal/CustomModal');

const ProgressionExportModal = ({ open, onSubmit, onClose, title }) => {
  const [filename, setFilename] = useState('');
  const [filetype, setFiletype] = useState(filetypes.json);

  const handleChangeFilename = (event) => {
    setFilename(event.target.value);
  };

  const handleFileTypeChange = (event) => {
    setFiletype(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(filetype, filename);
    onClose();
  };

  return (
    <CustomModal open={open} onClose={onClose} title={title}>
      <Box sx={{ marginTop: '1rem' }}>
        <TextField label="Filename" value={filename} onChange={handleChangeFilename} />

        <RadioGroup
          sx={{ display: 'flex', flexDirection: 'row', marginTop: '0.5rem' }}
          onChange={handleFileTypeChange}
          value={filetype}
        >
          <FormControlLabel value={filetypes.json} control={<Radio />} label={filetypes.json} />
          <FormControlLabel value={filetypes.txt} control={<Radio />} label={filetypes.txt} />
        </RadioGroup>

        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <Button variant="outlined" onClick={handleSubmit}>
            Export
          </Button>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default ProgressionExportModal;
