import { useState, createContext } from 'react';

export const ProgressionScalesContext = createContext();

export const ProgressionScalesProvider = ({ children }) => {
  const [progressionScaleInfo, setProgressionScaleInfo] = useState({});
  const [selectedProgressionScaleId, setSelectedProgressionScaleId] = useState('');
  const [scaleTransposedPattern, setScaleTransposedPattern] = useState([]);
  const [accidental, setAccidental] = useState('sharp');
  const [chordProgression, setChordProgression] = useState(null);

  const handleSelectProgressionScale = (scaleId) => {
    setSelectedProgressionScaleId(scaleId);
  };

  const defaultValue = {
    progressionScaleInfo,
    setProgressionScaleInfo,
    selectedProgressionScaleId,
    setSelectedProgressionScaleId,
    handleSelectProgressionScale,
    scaleTransposedPattern,
    setScaleTransposedPattern,
    accidental,
    setAccidental,
    chordProgression,
    setChordProgression,
  };

  return (
    <ProgressionScalesContext.Provider value={defaultValue}>
      {children}
    </ProgressionScalesContext.Provider>
  );
};
