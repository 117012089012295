import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { frets } from 'common/constants';

const Frets = ({ startFret, handleChangeFret }) => {
  return (
    <div>
      <Typography sx={{ color: 'text.primary' }}>Fret: {startFret}</Typography>

      <ToggleButtonGroup
        exclusive
        value={startFret}
        onChange={handleChangeFret}
        sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
      >
        {frets.map((fret) => (
          <ToggleButton key={fret} value={fret} sx={{ border: 'none', padding: 0 }}>
            <Typography
              sx={{
                textTransform: 'none',
                width: '2ch',
                border: '1px solid',
                borderColor: 'divider',
                padding: '0.1rem',
              }}
            >
              {fret}
            </Typography>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};

export default Frets;
