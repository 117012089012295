import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { notes } from 'common/constants';

const ChromaticNotes = ({ title, selectedRoot, onChange }) => {
  return (
    <>
      <Typography sx={{ color: 'text.primary' }}>
        {title}: {selectedRoot}
      </Typography>

      <ToggleButtonGroup
        exclusive
        value={selectedRoot}
        onChange={onChange}
        sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
      >
        {notes.map((note) => (
          <ToggleButton key={note} value={note} sx={{ border: 'none', padding: 0 }}>
            <Typography
              sx={{
                textTransform: 'none',
                width: '6ch',
                border: '1px solid',
                borderColor: 'divider',
                padding: '0.5rem',
              }}
            >
              {note}
            </Typography>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
};

export default ChromaticNotes;
