import { Typography } from '@mui/material';
import LeftNavBar from 'components/LeftNavBar/LeftNavBar';
import Page from 'components/Page/Page';

const Projects = () => {
  return (
    <Page>
      <LeftNavBar>
        <Typography>Projects</Typography>
      </LeftNavBar>

      <div>
        <p>Song Structure</p>

        <p>Progression</p>

        <p>Melody</p>

        <p>Tabs</p>
      </div>
    </Page>
  );
};

export default Projects;
