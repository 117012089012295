import { useContext } from 'react';
import Keyboard from 'components/Keyboard/Keyboard';
import ScaleInfo from './ScaleInfo';
import { Box } from '@mui/material';
import { ScalesContext } from 'store/ScalesProvider';
import Fretboard from 'components/Fretboard/Fretboard';

const ScaleDisplay = () => {
  const { activeScaleKeys, scaleTuningInfo, scaleInfo } = useContext(ScalesContext);

  return (
    <>
      {scaleInfo?.pattern && scaleTuningInfo?.pattern && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <ScaleInfo />

          <Keyboard activeKeys={activeScaleKeys} isPlayScale />

          <Fretboard />
        </Box>
      )}
    </>
  );
};

export default ScaleDisplay;
