import Chords from '../pages/Chords/Chords';
import Home from '../pages/Home/Home';
import Scales from '../pages/Scales/Scales';
import Theory from '../pages/Theory/Theory';
import ChordProgression from '../pages/ChordProgression/ChordProgression';
import MelodyGenerator from '../pages/MelodyGenerator/MelodyGenerator';
import Harmony from '../pages/Harmony/Harmony';
import Rhythm from 'pages/Rhythm/Rhythm';
import Projects from 'pages/Projects/Projects';
import ChordDisplay from 'pages/Chords/ChordDisplay';
import ScaleDisplay from 'pages/Scales/ScaleDisplay';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Login from 'pages/Login/Login';

const Router = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Home />}>
          <Route path="projects" element={<Projects />} />
          <Route path="chords" element={<Chords />}>
            <Route path=":root" element={<ChordDisplay />} />
            <Route path=":root/:chordId" element={<ChordDisplay />} />
          </Route>
          <Route path="scales" element={<Scales />}>
            <Route path=":root" element={<ScaleDisplay />} />
            <Route path=":root/:scaleId" element={<ScaleDisplay />} />
          </Route>
          <Route path="theory" element={<Theory />} />
          <Route path="chord-progression" element={<ChordProgression />} />
          <Route path="melody-generator" element={<MelodyGenerator />} />
          <Route path="rhythm" element={<Rhythm />} />
          <Route path="harmony" element={<Harmony />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default Router;
