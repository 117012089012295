import Page from 'components/Page/Page';
import Header from 'components/Header/Header';

const Rhythm = () => {
  return (
    <Page>
      <Header title='Rhythm' />
    </Page>
  );
};

export default Rhythm;
