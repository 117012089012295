import { useRef, useContext, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Box, Chip, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import Octave from 'components/Keyboard/Octave';
import HealingIcon from '@mui/icons-material/Healing';
import CachedIcon from '@mui/icons-material/Cached';
import { useState } from 'react';
import ReplaceChordModal from './ReplaceChordModal';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import useGetNotesAccidental from 'common/hooks/useGetNotesAccidental';
import { ProgressionScalesContext } from 'store/ProgressionScalesProvider';

const Chord = ({
  chord,
  index,
  moveChord,
  deleteChord,
  sectionId,
  onReplaceChord,
  onInvertChord,
  isDisplayPiano,
}) => {
  const [openReplaceModal, setOpenReplaceModal] = useState(false);
  const [isPianoVisible, setIsPianoVisible] = useState(false);
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'Chord',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddley = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddley) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddley) {
        return;
      }

      moveChord(dragIndex, hoverIndex, sectionId);

      item.index = hoverIndex;
    },
  });
  const { accidental } = useContext(ProgressionScalesContext);
  const { newNotes } = useGetNotesAccidental(chord.notes, accidental);

  useEffect(() => {
    setIsPianoVisible(isDisplayPiano);
  }, [isDisplayPiano]);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'Chord',
    item: () => ({ id: chord.id, index }),
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  }));

  drag(drop(ref));

  return (
    <>
      <Box
        ref={ref}
        style={{
          border: isDragging ? '2px solid blue' : '1px solid gray',
          padding: '0 0.5rem 0.5rem 0.5rem',
        }}
        data-handler-id={handlerId}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Box sx={{ flex: 1 }}>
              <Chip label={chord.numeral} />

              <IconButton onClick={() => onInvertChord(index, sectionId)}>
                <HealingIcon />
              </IconButton>

              <IconButton onClick={() => setOpenReplaceModal(true)}>
                <CachedIcon />
              </IconButton>

              <IconButton onClick={() => setIsPianoVisible((prev) => !prev)}>
                <RemoveRedEyeIcon />
              </IconButton>
            </Box>

            <IconButton onClick={() => deleteChord(index, sectionId)}>
              <ClearIcon />
            </IconButton>
          </Box>

          <div>{`${chord.formattedName} (${newNotes.join(' - ')})`}</div>

          {isPianoVisible && (
            <Box
              sx={{
                display: 'flex',
                height: '100px',
                width: 'fit-content',
                marginTop: '0.5rem',
              }}
            >
              <Octave octave="4" activeKeys={chord.notes} />
            </Box>
          )}
        </Box>
      </Box>

      <ReplaceChordModal
        open={openReplaceModal}
        onClose={() => setOpenReplaceModal(false)}
        title={`Replace Chord: ${chord.formattedName}`}
        submit={onReplaceChord}
        index={index}
        sectionId={sectionId}
      />
    </>
  );
};

export default Chord;
