import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { inversions } from 'common/constants';

const ChordInversion = ({
  handleChangeInversion,
  inversion,
  chordInfo,
  selectedChordId,
  selectedChordRoot,
}) => {
  const chordLength = chordInfo?.pattern?.length;
  const disabled = !(selectedChordId && selectedChordRoot);

  return (
    <Box sx={{ marginBottom: '0.5rem' }}>
      <Typography>Inversions</Typography>
      <ToggleButtonGroup
        exclusive
        value={inversion}
        onChange={handleChangeInversion}
        disabled={disabled}
      >
        {inversions.map((inversion, index) => {
          if (index + 1 > chordLength) return null;
          return (
            <ToggleButton key={inversion.name} value={inversion.value}>
              {inversion.name}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Box>
  );
};

export default ChordInversion;
