import { Box } from '@mui/material';

const LeftNavBar = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '300px',
        maxWidth: '300px',
        borderRight: '1px solid',
        borderColor: 'divider',
        gap: '0.5rem',
        padding: '0.5rem',
      }}
    >
      {children}
    </Box>
  );
};

export default LeftNavBar;
