import { getNote } from 'common/utils/utils';

const FretNotes = ({ notes, fretNumber, root, chordPatternNotes }) => {
  const style = (fretNumber) => ({
    borderTop: '1px solid grey',
    borderBottom: `${fretNumber === 0 ? '3px' : '1px'} solid`,
    borderBottomColor: fretNumber === 0 ? 'white' : 'grey',
    borderLeft: fretNumber === 0 ? 'none' : '1px solid grey',
    borderRight: fretNumber === 0 ? 'none' : '1px solid grey',
    padding: '0.2rem',
  });

  const styleNoFrame = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40px',
    height: '40px',
    padding: '0.2rem',
  };

  const styleNote = (isRootNote, isIncluded) => {
    return {
      display: 'flex',
      borderRadius: '50%',
      backgroundColor: isRootNote ? 'green' : isIncluded ? 'blue' : 'grey',
      width: '40px',
      height: '40px',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      left: '24px',
      fontSize: '0.8rem',
    };
  };

  return (
    <>
      {notes.map((noteIndex, index) => {
        const noteName = getNote(noteIndex);
        const isRoot = noteName === root;
        const isIncluded = chordPatternNotes.includes(noteName);

        return (
          <div
            key={`${fretNumber}-${noteIndex}-${index}`}
            style={index === 0 ? styleNoFrame : style(fretNumber)}
          >
            <div style={styleNote(isRoot, isIncluded)}>{noteName}</div>
          </div>
        );
      })}
    </>
  );
};

export default FretNotes;
