import { useState, useEffect } from 'react';
import { getTabGridChord, getTabGridScale } from 'common/utils/utils';

const Tablature = ({ fretsMatrix, pattern, startFret, tuning, isChord, chordFrets }) => {
  const [tab, setTab] = useState([]);

  useEffect(() => {
    if (!fretsMatrix.length) return;

    let tabGrid;

    if (isChord) {
      tabGrid = getTabGridChord(
        fretsMatrix.slice(startFret, startFret + chordFrets),
        pattern,
        startFret,
        tuning
      );
    } else {
      tabGrid = getTabGridScale(tuning, fretsMatrix, pattern);
    }

    setTab(tabGrid);
  }, [fretsMatrix, pattern, startFret, tuning, isChord, chordFrets]);

  const style = {
    display: 'flex',
    gap: '1rem',
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        fontSize: '1rem',
        fontFamily: 'courier',
      }}
    >
      {tab.map((line, index) => (
        <div style={style} key={`${line.stringName}-${index}`}>
          <div style={{ width: '6ch', textAlign: 'right' }}>{line.stringName}</div>
          <div>{line.tabLine} </div>
        </div>
      ))}
    </div>
  );
};

export default Tablature;
