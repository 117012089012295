import ApiClient from './ApiClient';

export const getChords = () => ApiClient.get('/chords');

export const addChord = (payload) => ApiClient.post('/chords', payload);

export const updateChord = (payload) => ApiClient.put('/chords', payload);

export const deleteChord = (id) => ApiClient.delete('/chords', { data: { id } });

export const getChord = (params) => ApiClient.get('/chords/chord', { params });
