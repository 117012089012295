import { useState, createContext, useCallback } from 'react';
import { toast } from 'react-toastify';
import { getTunings } from 'api/tunigsAPI';
import { getScales } from 'api/scalesAPI';
import { getChords } from 'api/chordsAPI';
import { useEffect } from 'react';
import { getProgressionScales } from 'api/ApiChordProgression';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [tunings, setTunings] = useState([]);
  const [chords, setChords] = useState([]);
  const [scales, setScales] = useState([]);
  const [reloadChords, setReloadChords] = useState(false);
  const [reloadTunings, setReloadTunings] = useState(false);
  const [reloadScales, setReloadScales] = useState(false);
  const [progressionScales, setProgressionScales] = useState([]);
  const [reloadProgressionScales, setReloadProgressionScales] = useState(false);

  const fetchChords = useCallback(async () => {
    try {
      const { data } = await getChords();
      setChords(data.chords);
    } catch (error) {
      console.error(error);
      toast.error('Cannot fetch chords');
    }
  }, []);

  const fetchTunings = useCallback(async () => {
    try {
      const { data } = await getTunings();
      setTunings(data.tunings);
    } catch (error) {
      console.error(error);
      toast.error('Cannot fetch tunings');
    }
  }, []);

  const fetchScales = useCallback(async () => {
    try {
      const { data } = await getScales();
      setScales(data.scales);
    } catch (error) {
      console.error(error);
      toast.error('Cannot fetch scales');
    }
  }, []);

  const fetchProgresionScales = useCallback(async () => {
    try {
      const { data } = await getProgressionScales();
      setProgressionScales(data.scales);
    } catch (error) {
      console.error(error);
      toast.error('Cannot fetch progression scales');
    }
  }, []);

  useEffect(() => {
    fetchChords();
    fetchScales();
    fetchTunings();
    fetchProgresionScales();
  }, [fetchChords, fetchProgresionScales, fetchScales, fetchTunings]);

  useEffect(() => {
    if (reloadChords) {
      fetchChords();
      setReloadChords(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadChords]);

  useEffect(() => {
    if (reloadTunings) {
      fetchTunings();
      setReloadTunings(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadTunings]);

  useEffect(() => {
    if (reloadScales) {
      fetchScales();
      setReloadScales(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadScales]);

  useEffect(() => {
    if (reloadProgressionScales) {
      fetchProgresionScales();
      setReloadProgressionScales(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadProgressionScales]);

  const defaultValue = {
    tunings,
    setTunings,
    chords,
    setChords,
    scales,
    setScales,
    progressionScales,
    setProgressionScales,
    setReloadProgressionScales,
    fetchChords,
    fetchTunings,
    fetchScales,
    reloadChords,
    setReloadChords,
    reloadTunings,
    setReloadTunings,
    reloadScales,
    setReloadScales,
  };

  return <AppContext.Provider value={defaultValue}>{children}</AppContext.Provider>;
};
