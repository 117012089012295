import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

const AccidentalSwitch = ({ accidental, onChange }) => {
  return (
    <FormControl>
      <FormLabel>Sharp(♯)/Flat(♭)</FormLabel>
      <RadioGroup
        value={accidental}
        onChange={onChange}
        sx={{ display: 'flex', flexDirection: 'row' }}
      >
        <FormControlLabel value="sharp" control={<Radio />} label="♯" />
        <FormControlLabel value="flat" control={<Radio />} label="♭" />
      </RadioGroup>
    </FormControl>
  );
};

export default AccidentalSwitch;
