import { useState, createContext } from 'react';

export const KeyboardContext = createContext();

export const KeyboardProvider = ({ children }) => {
  const [notesToPlay, setNotesToPlay] = useState([]);
  const [play, setPlay] = useState(false);

  const defaultValue = {
    notesToPlay,
    setNotesToPlay,
    play,
    setPlay,
  };

  return <KeyboardContext.Provider value={defaultValue}>{children}</KeyboardContext.Provider>;
};
