import { useRef, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import Chord from '../Chord';
import { grey } from '@mui/material/colors';
import { v4 as uuid } from 'uuid';
import ClearIcon from '@mui/icons-material/Clear';
import { useDrag, useDrop } from 'react-dnd';
import AddIcon from '@mui/icons-material/Add';
import AddChordModal from '../AddChordModal';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const Section = ({
  section,
  index,
  onMoveChord,
  onDeleteChord,
  onDelete,
  onMove,
  onInvert,
  onReplace,
  onAdd,
  onClone,
}) => {
  const ref = useRef(null);
  const [openAddChord, setOpenAddChord] = useState(false);
  const [isPianoVisible, setIsPianoVisible] = useState(false);

  const [{ handlerId }, drop] = useDrop({
    accept: 'Section',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddley = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddley) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddley) return;

      onMove(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'Section',
    item: () => ({ id: section.id, index }),
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  }));

  drag(drop(ref));

  return (
    <>
      <Box sx={{ display: 'flex', gap: '0.5rem' }}>
        <Box
          ref={ref}
          data-handler-id={handlerId}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            backgroundColor: grey[800],
            padding: '0.5rem',
            border: isDragging ? '2px solid blue' : '1px solid gray',
          }}
          key={section.id}
        >
          {section.chordProgression.map((chord, index) => (
            <Chord
              chord={chord}
              key={uuid()}
              index={index}
              moveChord={onMoveChord}
              deleteChord={onDeleteChord}
              sectionId={section.id}
              onReplaceChord={onReplace}
              onInvertChord={onInvert}
              isDisplayPiano={isPianoVisible}
            />
          ))}
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
          <IconButton onClick={() => setOpenAddChord(true)}>
            <AddIcon />
          </IconButton>

          <IconButton onClick={() => onClone(section.id)}>
            <ContentCopyIcon />
          </IconButton>

          <IconButton onClick={() => setIsPianoVisible((prev) => !prev)}>
            <RemoveRedEyeIcon />
          </IconButton>

          <IconButton onClick={() => onDelete(section.id)}>
            <ClearIcon />
          </IconButton>
        </Box>
      </Box>

      <AddChordModal
        open={openAddChord}
        onClose={() => setOpenAddChord(false)}
        title="Add Chord"
        submit={onAdd}
        sectionId={section.id}
      />
    </>
  );
};

export default Section;
