export const notes = [
  'C',
  'C♯-D♭',
  'D',
  'D♯-E♭',
  'E',
  'F',
  'F♯-G♭',
  'G',
  'G♯-A♭',
  'A',
  'A♯-B♭',
  'B',
];

export const whiteNotes = notes.filter((note) => !note.includes('♯'));

export const sharpNotes = notes
  .filter((note) => note.includes('♯'))
  .map((note) => note.split('-')[0]);

export const accidentalEnarmonicNotes = notes.filter((note) => note.includes('♯'));

export const SHARP = '♯';

export const FLAT = '♭';

export const WHITE_KEY_WIDTH = 35;

export const BLACK_KEY_WIDTH = 25;

export const BLACK_KEY_HEIGHT_PERCENT = 65;

export const blackKeys = [
  { note: 'C♯-D♭', index: 1, offset: 10 },
  { note: 'D♯-E♭', index: 2, offset: 20 },
  { note: 'F♯-G♭', index: 4, offset: 12 },
  { note: 'G♯-A♭', index: 5, offset: 18 },
  { note: 'A♯-B♭', index: 6, offset: 24 },
];

export const inversions = [
  { name: 'Root', value: 0 },
  { name: 'First', value: 1 },
  { name: 'Second', value: 2 },
  { name: 'Third', value: 3 },
];

export const frets = Array.from({ length: 21 }, (_, i) => i);

export const fretboard = Array.from({ length: 25 }, (_, i) => i);

export const MAX_FRETS = 24;

export const MIDI_NOTES = [
  { number: 0, name: 'C1', pitch: 8.176 },
  { number: 1, name: 'C#1', pitch: 8.662 },
  { number: 2, name: 'D1', pitch: 9.177 },
  { number: 3, name: 'D#1', pitch: 9.723 },
  { number: 4, name: 'E1', pitch: 10.301 },
  { number: 5, name: 'F1', pitch: 10.913 },
  { number: 6, name: 'F#1', pitch: 11.562 },
  { number: 7, name: 'G1', pitch: 12.25 },
  { number: 8, name: 'G#1', pitch: 12.978 },
  { number: 9, name: 'A1', pitch: 13.75 },
  { number: 10, name: 'A#1', pitch: 14.568 },
  { number: 11, name: 'B1', pitch: 15.434 },
  { number: 12, name: 'C0', pitch: 16.352 },
  { number: 13, name: 'C#0', pitch: 17.324 },
  { number: 14, name: 'D0', pitch: 18.354 },
  { number: 15, name: 'D#0', pitch: 19.445 },
  { number: 16, name: 'E0', pitch: 20.602 },
  { number: 17, name: 'F0', pitch: 21.827 },
  { number: 18, name: 'F#0', pitch: 23.125 },
  { number: 19, name: 'G0', pitch: 24.5 },
  { number: 20, name: 'G#0', pitch: 25.957 },
  { number: 21, name: 'A0', pitch: 27.5 },
  { number: 22, name: 'A#0', pitch: 29.135 },
  { number: 23, name: 'B0', pitch: 30.868 },
  { number: 24, name: 'C1', pitch: 32.703 },
  { number: 25, name: 'C#1', pitch: 34.648 },
  { number: 26, name: 'D1', pitch: 36.708 },
  { number: 27, name: 'D#1', pitch: 38.891 },
  { number: 28, name: 'E1', pitch: 41.203 },
  { number: 29, name: 'F1', pitch: 43.654 },
  { number: 30, name: 'F#1', pitch: 46.249 },
  { number: 31, name: 'G1', pitch: 48.999 },
  { number: 32, name: 'G#1', pitch: 51.913 },
  { number: 33, name: 'A1', pitch: 55.0 },
  { number: 34, name: 'A#1', pitch: 58.27 },
  { number: 35, name: 'B1', pitch: 61.735 },
  { number: 36, name: 'C2', pitch: 65.406 },
  { number: 37, name: 'C#2', pitch: 69.296 },
  { number: 38, name: 'D2', pitch: 73.416 },
  { number: 39, name: 'D#2', pitch: 77.782 },
  { number: 40, name: 'E2', pitch: 82.407 },
  { number: 41, name: 'F2', pitch: 87.307 },
  { number: 42, name: 'F#2', pitch: 92.499 },
  { number: 43, name: 'G2', pitch: 97.999 },
  { number: 44, name: 'G#2', pitch: 103.826 },
  { number: 45, name: 'A2', pitch: 110.0 },
  { number: 46, name: 'A#2', pitch: 116.541 },
  { number: 47, name: 'B2', pitch: 123.471 },
  { number: 48, name: 'C3', pitch: 130.813 },
  { number: 49, name: 'C#3', pitch: 138.591 },
  { number: 50, name: 'D3', pitch: 146.832 },
  { number: 51, name: 'D#3', pitch: 155.563 },
  { number: 52, name: 'E3', pitch: 164.814 },
  { number: 53, name: 'F3', pitch: 174.614 },
  { number: 54, name: 'F#3', pitch: 184.997 },
  { number: 55, name: 'G3', pitch: 195.998 },
  { number: 56, name: 'G#3', pitch: 207.652 },
  { number: 57, name: 'A3', pitch: 220.0 },
  { number: 58, name: 'A#3', pitch: 233.082 },
  { number: 59, name: 'B3', pitch: 246.942 },
  { number: 60, name: 'C4', pitch: 261.626 },
  { number: 61, name: 'C#4', pitch: 277.183 },
  { number: 62, name: 'D4', pitch: 293.665 },
  { number: 63, name: 'D#4', pitch: 311.127 },
  { number: 64, name: 'E4', pitch: 329.628 },
  { number: 65, name: 'F4', pitch: 349.228 },
  { number: 66, name: 'F#4', pitch: 369.994 },
  { number: 67, name: 'G4', pitch: 391.995 },
  { number: 68, name: 'G#4', pitch: 415.305 },
  { number: 69, name: 'A4', pitch: 440.0 },
  { number: 70, name: 'A#4', pitch: 466.164 },
  { number: 71, name: 'B4', pitch: 493.883 },
  { number: 72, name: 'C5', pitch: 523.251 },
  { number: 73, name: 'C#5', pitch: 554.365 },
  { number: 74, name: 'D5', pitch: 587.33 },
  { number: 75, name: 'D#5', pitch: 622.254 },
  { number: 76, name: 'E5', pitch: 659.255 },
  { number: 77, name: 'F5', pitch: 698.456 },
  { number: 78, name: 'F#5', pitch: 739.989 },
  { number: 79, name: 'G5', pitch: 783.991 },
  { number: 80, name: 'G#5', pitch: 830.609 },
  { number: 81, name: 'A5', pitch: 880.0 },
  { number: 82, name: 'A#5', pitch: 932.328 },
  { number: 83, name: 'B5', pitch: 987.767 },
  { number: 84, name: 'C6', pitch: 1046.502 },
  { number: 85, name: 'C#6', pitch: 1108.731 },
  { number: 86, name: 'D6', pitch: 1174.659 },
  { number: 87, name: 'D#6', pitch: 1244.508 },
  { number: 88, name: 'E6', pitch: 1318.51 },
  { number: 89, name: 'F6', pitch: 1396.913 },
  { number: 90, name: 'F#6', pitch: 1479.978 },
  { number: 91, name: 'G6', pitch: 1567.982 },
  { number: 92, name: 'G#6', pitch: 1661.219 },
  { number: 93, name: 'A6', pitch: 1760.0 },
  { number: 94, name: 'A#6', pitch: 1864.655 },
  { number: 95, name: 'B6', pitch: 1975.533 },
  { number: 96, name: 'C7', pitch: 2093.005 },
  { number: 97, name: 'C#7', pitch: 2217.461 },
  { number: 98, name: 'D7', pitch: 2349.318 },
  { number: 99, name: 'D#7', pitch: 2489.016 },
  { number: 100, name: 'E7', pitch: 2637.02 },
  { number: 101, name: 'F7', pitch: 2793.826 },
  { number: 102, name: 'F#7', pitch: 2959.955 },
  { number: 103, name: 'G7', pitch: 3135.963 },
  { number: 104, name: 'G#7', pitch: 3322.438 },
  { number: 105, name: 'A7', pitch: 3520.0 },
  { number: 106, name: 'A#7', pitch: 3729.31 },
  { number: 107, name: 'B7', pitch: 3951.066 },
  { number: 108, name: 'C8', pitch: 4186.009 },
  { number: 109, name: 'C#8', pitch: 4434.922 },
  { number: 110, name: 'D8', pitch: 4698.636 },
  { number: 111, name: 'D#8', pitch: 4978.032 },
  { number: 112, name: 'E8', pitch: 5274.041 },
  { number: 113, name: 'F8', pitch: 5587.652 },
  { number: 114, name: 'F#8', pitch: 5919.911 },
  { number: 115, name: 'G8', pitch: 6271.927 },
  { number: 116, name: 'G#8', pitch: 6644.875 },
  { number: 117, name: 'A8', pitch: 7040.0 },
  { number: 118, name: 'A#8', pitch: 7458.62 },
  { number: 119, name: 'B8', pitch: 7902.133 },
  { number: 120, name: 'C9', pitch: 8372.018 },
  { number: 121, name: 'C#9', pitch: 8869.844 },
  { number: 122, name: 'D9', pitch: 9397.273 },
  { number: 123, name: 'D#9', pitch: 9956.063 },
  { number: 124, name: 'E9', pitch: 10548.08 },
  { number: 125, name: 'F9', pitch: 11175.3 },
  { number: 126, name: 'F#9', pitch: 11839.82 },
  { number: 127, name: 'G9', pitch: 12543.85 },
];

export const OCTAVE_OFFSET = 3;

export const filetypes = {
  json: 'json',
  txt: 'txt',
};
