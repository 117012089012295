import { useContext, useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { getNotesToPlay } from 'common/utils/utils';
import { ScalesContext } from 'store/ScalesProvider';
import { KeyboardContext } from 'components/Keyboard/KeyboardContext';

const ScaleInfo = () => {
  const [scaleNotes, setScaleNotes] = useState('');
  const { activeScaleKeys, scaleInfo, selectedScaleRoot } = useContext(ScalesContext);

  const { setNotesToPlay, setPlay } = useContext(KeyboardContext);

  useEffect(() => {
    const tempNotes = Object.values(activeScaleKeys).flat().join(' - ');
    setScaleNotes(tempNotes);
  }, [activeScaleKeys]);

  const handlePlayClick = () => {
    const notesToPlay = getNotesToPlay(activeScaleKeys);
    setNotesToPlay(notesToPlay);
    setPlay(true);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        color: 'text.primary',
      }}
    >
      <Typography variant="h5">
        {selectedScaleRoot} {scaleInfo.name}
        <IconButton onClick={handlePlayClick}>
          <PlayArrowIcon />
        </IconButton>
      </Typography>

      <Typography sx={{ color: 'text.primary' }}>({scaleNotes})</Typography>
    </Box>
  );
};

export default ScaleInfo;
