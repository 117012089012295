import { useContext } from 'react';
import Keyboard from 'components/Keyboard/Keyboard';
import ChordInfo from './ChordInfo';
import StringChord from 'components/StringChord/StringChord';
import { Box } from '@mui/material';
import { ChordsContext } from 'store/ChordsProvider';

const ChordDisplay = () => {
  const {
    activeChordKeys,
    startFret,
    chordInfo,
    selectedChordRoot,
    chordTuningInfo,
    setStartFret,
    totalFrets,
    fretsMatrix,
    fretsMatrixChordPattern,
    fretsMatrixPatternNotes,
    numStrings,
    chordFrets,
  } = useContext(ChordsContext);

  return (
    <>
      {chordInfo?.pattern && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
          <ChordInfo />
          <Keyboard activeKeys={activeChordKeys} isPlayChord />
          <StringChord
            chordInfo={chordInfo}
            startFret={startFret}
            selectedChordRoot={selectedChordRoot}
            chordTuningInfo={chordTuningInfo}
            setStartFret={setStartFret}
            totalFrets={totalFrets}
            fretsMatrix={fretsMatrix}
            fretsMatrixChordPattern={fretsMatrixChordPattern}
            fretsMatrixPatternNotes={fretsMatrixPatternNotes}
            numStrings={numStrings}
            chordFrets={chordFrets}
          />
        </Box>
      )}
    </>
  );
};

export default ChordDisplay;
