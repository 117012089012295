import { useEffect, useState } from 'react';
import { TextField, Box, Stack } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ChordFormValidation from './formValidation';
import ChromaticNotes from 'components/ChromaticNotes/ChromaticNotes';
import { getNoteIndex, stringToArrayNotesPattern } from 'common/utils/utils';
import NoteChip from 'components/NoteChip/NoteChip';

const ChordForm = ({ onSubmit, defaultValues, formRef, setIsValid, setIsDirty }) => {
  const [selectedNote, setSelectedNote] = useState('');
  const [notesPattern, setNotesPattern] = useState([]);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(ChordFormValidation()),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    trigger,
    formState: { errors, isValid, isDirty },
  } = methods;

  const watchPattern = watch('pattern');

  const handleNoteChange = (_, newNote) => {
    if (newNote !== null) {
      setSelectedNote(newNote);
    }
  };

  useEffect(() => {
    if (!selectedNote) return;
    const newPattern = [...notesPattern];
    newPattern.push(selectedNote);
    setNotesPattern(newPattern);

    const noteIndex = getNoteIndex(selectedNote);
    const currentPattern = getValues('pattern');
    let newIndexPattern = '';

    if (currentPattern === '') {
      newIndexPattern = noteIndex;
    } else {
      newIndexPattern = `${currentPattern},${noteIndex}`;
    }

    setValue('pattern', newIndexPattern);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNote]);

  const handleDeleteNote = (index) => {
    const newPattern = [...notesPattern];
    const noteIndex = getNoteIndex(notesPattern[index]);

    newPattern.splice(index, 1);
    setNotesPattern(newPattern);
    let newIndexPattern;
    let newFilteredPattern;

    const values = getValues('pattern');

    if (values.toString().length <= 1) {
      newFilteredPattern = [''];
    } else {
      newIndexPattern = values.split(',');
      newFilteredPattern = newIndexPattern.filter((item) => item !== noteIndex.toString());
    }

    setValue('pattern', newFilteredPattern.toString());
  };

  useEffect(() => {
    setIsValid(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  useEffect(() => {
    setIsDirty(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    const pattern = getValues('pattern');
    if (!pattern) return;
    const newPattern = stringToArrayNotesPattern(pattern);
    setNotesPattern(newPattern);
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPattern]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
      <Box
        sx={{
          display: 'flex',
          padding: '0.5rem 0',
          gap: '0.5rem',
          flexDirection: 'column',
          width: 350,
        }}
      >
        <Controller
          name="name"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value, name } }) => (
            <TextField
              label="Name"
              name={name}
              fullWidth
              onChange={onChange}
              value={value}
              error={!!errors[name]}
              helperText={errors[name]?.message}
              data-testid="name"
            />
          )}
        />

        <Controller
          name="shortName"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value, name } }) => (
            <TextField
              label="ShortName"
              name={name}
              fullWidth
              onChange={onChange}
              value={value}
              error={!!errors[name]}
              helperText={errors[name]?.message}
              data-testid="shortName"
            />
          )}
        />

        <ChromaticNotes selected={selectedNote} onChange={handleNoteChange} title="Note" />

        <Stack
          direction="row"
          spacing={1}
          padding={1}
          sx={{
            border: '1px solid',
            borderColor: 'text.primary',
            minHeight: '64px',
            flexWrap: 'wrap',
            rowGap: '0.5rem',
          }}
        >
          {notesPattern.map((note, index) => (
            <NoteChip
              key={`${note}-${index}`}
              label={note}
              onDelete={() => handleDeleteNote(index)}
            />
          ))}
        </Stack>

        <Controller
          name="pattern"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value, name } }) => (
            <TextField
              label="Pattern"
              name={name}
              fullWidth
              onChange={onChange}
              value={value}
              error={!!errors[name]}
              helperText={errors[name]?.message}
              data-testid="pattern"
            />
          )}
        />
      </Box>
    </form>
  );
};

export default ChordForm;
