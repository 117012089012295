import { Fragment, useState } from 'react';
import { getRandomString } from 'common/utils/utils';
import ChordInfo from './ChordInfo';
import FretNotes from './FretNotes';
import FretNumber from './FretNumber';
import Tablature from '../Tablature/Tablature';
import Frets from 'components/Frets/Frets';
import { Box } from '@mui/material';
import { useEffect } from 'react';

const StringChord = ({
  selectedChordRoot,
  chordTuningInfo,
  chordInfo,
  startFret,
  setStartFret,
  fretsMatrix,
  fretsMatrixChordPattern,
  fretsMatrixPatternNotes,
  numStrings,
  chordFrets,
}) => {
  const handleChangeFret = (_, newFret) => {
    if (newFret !== null) setStartFret(newFret);
  };

  const [slicedFretMatrix, setSlicedFretMatrix] = useState([]);

  useEffect(() => {
    setSlicedFretMatrix(fretsMatrix.slice(startFret, startFret + chordFrets));
  }, [chordFrets, fretsMatrix, startFret]);

  return (
    <Box sx={{ display: 'flex', gap: '4rem' }}>
      <Box sx={{ width: '300px' }}>
        <div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: `repeat(${numStrings + 1}, 1fr)`,
            }}
          >
            {slicedFretMatrix.map((fret) => {
              return (
                <Fragment key={getRandomString()}>
                  <FretNumber fretNumber={fret.number} />
                  <FretNotes
                    notes={fret.notes}
                    fretNumber={fret.number}
                    root={selectedChordRoot}
                    chordPatternNotes={fretsMatrixPatternNotes}
                  />
                </Fragment>
              );
            })}
          </div>
          <ChordInfo chordInfo={chordInfo} root={selectedChordRoot} />
        </div>

        {chordTuningInfo?.pattern && (
          <Tablature
            fretsMatrix={fretsMatrix}
            pattern={fretsMatrixChordPattern}
            startFret={startFret}
            tuning={chordTuningInfo.pattern}
            isChord
            chordFrets={chordFrets}
          />
        )}
      </Box>

      <Box sx={{ width: '300px' }}>
        <Frets startFret={startFret} handleChangeFret={handleChangeFret} />
      </Box>
    </Box>
  );
};

export default StringChord;
