import { useEffect, useState } from 'react';
import Instruments from 'webaudio-instruments';
import { Box, MenuItem, TextField } from '@mui/material';

const PLAY_SPEED = 0.35;

const MidiPlayer = ({ notes, isChord, isScale, isPlay, setIsPlay }) => {
  const [player, setPlayer] = useState(null);
  const [instruments, setInstruments] = useState([]);
  const [selectedInstrument, setSelectedInstrument] = useState(0);

  useEffect(() => {
    setPlayer(new Instruments());
  }, []);

  useEffect(() => {
    if (player) setInstruments(player.names);
  }, [player]);

  const handleInstrumentChange = (event) => {
    setSelectedInstrument(event.target.value);
  };

  const play = (note, delay = 0) => {
    if (!player) return;
    player.play(
      selectedInstrument, // instrument: 24 is "Acoustic Guitar (nylon)"
      note, // note: midi number or frequency in Hz (if > 127)
      0.5, // velocity: 0..1
      delay, // delay in seconds
      1, // duration in seconds
      0, // (optional - specify channel for tinysynth to use)
      0 // (optional - override envelope "attack" parameter)
    );
  };

  const playScale = (notes) => {
    notes.forEach((note, index) => {
      const delay = index > 0 ? index * PLAY_SPEED : 0;
      play(note, delay);
    });
  };

  const playChord = (notes) => {
    notes.forEach((note) => play(note));
  };

  useEffect(() => {
    if (!notes.length || !isPlay) return;

    if (isChord) {
      playChord(notes);
    } else if (isScale) {
      playScale(notes);
    }

    setIsPlay(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes, isPlay]);

  return (
    <Box>
      {instruments.length > 0 && (
        <TextField
          select
          value={selectedInstrument}
          onChange={handleInstrumentChange}
          label="Instrument"
        >
          {instruments.map((instrument, index) => (
            <MenuItem key={instrument} value={index}>
              {instrument}
            </MenuItem>
          ))}
        </TextField>
      )}
    </Box>
  );
};

export default MidiPlayer;
