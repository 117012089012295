import ApiClient from './ApiClient';

export const getTunings = () => ApiClient.get('/tunings');

export const addTuning = (payload) => ApiClient.post('/tunings', payload);

export const updateTuning = (payload) => ApiClient.put('/tunings', payload);

export const deleteTuning = (id) =>
  ApiClient.delete('/tunings', { data: { id } });
