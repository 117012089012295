import { useState, createContext } from 'react';

export const ChordsContext = createContext();

export const ChordsProvider = ({ children }) => {
  const [activeChordKeys, setActiveChordKeys] = useState({});
  const [tuningChordInfo, setTuningChordInfo] = useState({});
  const [chordTuningInfo, setChordTuningInfo] = useState({});
  const [selectedChordRoot, setSelectedChordRoot] = useState('C');
  const [selectedChordTuningId, setSelectedChordTuningId] = useState('');
  const [selectedChordId, setSelectedChordId] = useState('');
  const [chordInfo, setChordInfo] = useState({});
  const [inversion, setInversion] = useState(0);
  const [startFret, setStartFret] = useState(0);
  const [totalFrets, setTotalFrets] = useState(25);
  const [fretsMatrix, setFretsMatrix] = useState([]);
  const [fretsMatrixChordPattern, setFretsMatrixChordPattern] = useState([]);
  const [fretsMatrixPatternNotes, setFretsMatrixPatternNotes] = useState([]);
  const [numStrings, setNumStrings] = useState([]);
  const [chordFrets, setChordFrets] = useState(5);

  const defaultValue = {
    activeChordKeys,
    setActiveChordKeys,
    tuningChordInfo,
    setTuningChordInfo,
    selectedChordRoot,
    setSelectedChordRoot,
    selectedChordTuningId,
    setSelectedChordTuningId,
    chordTuningInfo,
    setChordTuningInfo,
    selectedChordId,
    setSelectedChordId,
    chordInfo,
    setChordInfo,
    inversion,
    setInversion,
    startFret,
    setStartFret,
    totalFrets,
    setTotalFrets,
    fretsMatrix,
    setFretsMatrix,
    fretsMatrixChordPattern,
    setFretsMatrixChordPattern,
    fretsMatrixPatternNotes,
    setFretsMatrixPatternNotes,
    numStrings,
    setNumStrings,
    chordFrets,
    setChordFrets,
  };

  return <ChordsContext.Provider value={defaultValue}>{children}</ChordsContext.Provider>;
};
