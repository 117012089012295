import { notes, MIDI_NOTES, OCTAVE_OFFSET } from 'common/constants';

export const getRandomString = (length = 10) => {
  if (typeof length === 'number') {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let randomString = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * (chars.length - 1));
      randomString += chars.charAt(randomIndex);
    }

    return randomString;
  }
  return '';
};

export const getNote = (value) => {
  const noteIndex = getNormalizedNoteIndex(value);
  const note = notes[noteIndex];
  return note;
};

export const getNoteIndex = (note) => {
  const index = notes.findIndex((item) => item === note);
  return index;
};

export const getNormalizedNoteIndex = (value) => {
  let noteIndex = value;
  if (value >= 12 && value < 24) noteIndex = value - 12;
  if (value >= 24 && value < 36) noteIndex = value - 24;
  return noteIndex;
};

export const playAudio = (file) => {
  const audio = new Audio(file);
  audio.play();
};

export const getMidiNoteNumberFromName = (noteName) => {
  const midiNoteNumber = MIDI_NOTES.find((midiNote) => midiNote.name === noteName).number;
  return midiNoteNumber;
};

export const getNotesToPlay = (activeKeys) => {
  const notesToPlay = [];

  for (const [octave, notes] of Object.entries(activeKeys)) {
    if (!notes.length) continue;
    const octaveNumber = octave.split('octave')[1];
    const notesNormalized = notes.map((note) => {
      let parsedNote = note;

      if (note.includes('♯')) {
        parsedNote = note.split('-')[0].replace('♯', '#');
      }
      const noteName = `${parsedNote}${Number(octaveNumber) + OCTAVE_OFFSET}`;

      const midiNoteNumber = getMidiNoteNumberFromName(noteName);
      return midiNoteNumber;
    });
    notesToPlay.push(...notesNormalized);
  }

  return notesToPlay;
};

export const getTabMatrix = (fretsMatrix, chordPattern) => {
  return fretsMatrix.map((fret, fretIndex) =>
    fret.notes.map((note) => (chordPattern.includes(getNormalizedNoteIndex(note)) ? fretIndex : -1))
  );
};

export const getScaleTabMatrix = (fretsMatrix, scalePattern) => {
  return fretsMatrix.map((stringNotes) =>
    stringNotes.map((note) =>
      scalePattern.includes(getNormalizedNoteIndex(note.note)) ? note.number : -1
    )
  );
};

export const transposeMatrix = (matrix) =>
  matrix[0].map((_, column) => matrix.map((row) => row[column]));

export const getTabGridChord = (fretsMatrix, chordPattern, startFret, tuning) => {
  const stringNames = tuning.map((note) => getNote(note));
  const tabMatrix = getTabMatrix(fretsMatrix, chordPattern);
  const stringFrets = transposeMatrix(tabMatrix);

  const TAB_START = '|--';
  const TAB_EMPTY_NOTE = '---';
  const TAB_SEPARATOR = (note) => (note > 9 ? '-' : '--');
  const TAB_END = '--|';

  const tab = stringFrets.map((item, index) => {
    let tabLine = TAB_START;
    let newNote = '';

    item.forEach((note) => {
      if (note === -1) {
        newNote = TAB_EMPTY_NOTE;
      } else {
        newNote = note + startFret;
        newNote += TAB_SEPARATOR(newNote);
      }

      tabLine += newNote;
    });
    return { stringName: stringNames[index], tabLine: `${tabLine}${TAB_END}` };
  });
  return tab.reverse();
};

export const getTabGridScale = (tuning, fretsMatrix, scalePattern) => {
  const stringNames = tuning.map((note) => getNote(note));
  const tabMatrix = getScaleTabMatrix(fretsMatrix, scalePattern);

  const TAB_START = '|--';
  const TAB_EMPTY_NOTE = '---';
  const TAB_SEPARATOR = (note) => (note > 9 ? '-' : '--');
  const TAB_END = '--|';

  const tab = tabMatrix.map((item, index) => {
    let tabLine = TAB_START;
    let newNote = '';

    item.forEach((note) => {
      if (note === -1) {
        newNote = TAB_EMPTY_NOTE;
      } else {
        newNote = note;
        newNote += TAB_SEPARATOR(newNote);
      }

      tabLine += newNote;
    });
    return { stringName: stringNames[index], tabLine: `${tabLine}${TAB_END}` };
  });
  return tab.reverse();
};

export const stringToArrayNotesPattern = (pattern) => {
  if (!pattern.toString().includes(',')) {
    return [getNote(pattern)];
  } else {
    return pattern.split(',').map((noteIndex) => getNote(noteIndex));
  }
};

export const transposeNote = (note, root) => {
  let transposed = 0;
  transposed = note - root;
  transposed = transposed < 0 ? transposed + 12 : transposed;
  return transposed;
};

export const getDisplayNoteAccidental = (note, accidental) => {
  if (note.length === 1) return note;

  let displayNote;
  switch (accidental) {
    case 'sharp':
      displayNote = note.split('-')[0];
      break;
    case 'flat':
      displayNote = note.split('-')[1];
      break;
    default:
      displayNote = note.split('-')[0];
  }

  return displayNote;
};
