const FretNumber = ({ fretNumber }) => {
  const styleFretNumber = {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    padding: '0.2rem',
    height: '36px',
    fontSize: '1.2rem',
  };

  return <div style={styleFretNumber}>{fretNumber}</div>;
};

export default FretNumber;
