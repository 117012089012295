import { Box, Chip } from '@mui/material';

const NoteChip = ({ label, onDelete }) => {
  return (
    <Box>
      <Chip label={label} onDelete={onDelete} />
    </Box>
  );
};

export default NoteChip;
