import { Button, Box } from '@mui/material';
import CustomModal from 'components/CustomModal/CustomModal';
import { useState, useRef } from 'react';
import TuningForm from './TuningForm';

const TuningModal = ({ open, onClose, title, submit, isEdit, tuning }) => {
  const formRef = useRef();
  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const handleSubmit = async (data) => {
    await submit(data);
    onClose();
  };

  const normalizeData = (data) => {
    if (!isEdit) return;
    const normalized = {
      id: data.id,
      name: data.name,
      pattern: data.pattern.toString(),
    };

    return normalized;
  };

  return (
    <CustomModal open={open} onClose={onClose} title={title}>
      <TuningForm
        onSubmit={handleSubmit}
        defaultValues={normalizeData(tuning)}
        formRef={formRef}
        setIsDirty={setIsDirty}
        setIsValid={setIsValid}
      />

      <Box
        sx={{
          display: 'flex',
          gap: '0.5rem',
          justifyContent: 'flex-end',
          marginTop: '1rem',
        }}
      >
        <Button
          variant="outlined"
          color="success"
          disabled={!isValid || !isDirty}
          onClick={() => {
            formRef.current?.dispatchEvent(
              new Event('submit', { cancelable: true, bubbles: true })
            );
          }}
        >
          {isEdit ? 'Edit' : 'Add'}
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </CustomModal>
  );
};

export default TuningModal;
