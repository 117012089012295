import { useContext, useEffect } from 'react';
import ChordsList from 'components/ChordsList/ChordsList';
import { ChordsContext } from 'store/ChordsProvider';
import ChromaticNotes from 'components/ChromaticNotes/ChromaticNotes';
import Tunings from 'components/Tunings/Tunings';
import LeftNavBar from 'components/LeftNavBar/LeftNavBar';
import Page from 'components/Page/Page';
import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getChord } from 'api/chordsAPI';
import { toast } from 'react-toastify';

const Chords = () => {
  const {
    setActiveChordKeys,
    selectedChordRoot,
    selectedChordTuningId,
    setSelectedChordTuningId,
    setChordTuningInfo,
    chordTuningInfo,
    setSelectedChordRoot,
    selectedChordId,
    setSelectedChordId,
    setChordInfo,
    inversion,
    setInversion,
    startFret,
    setStartFret,
    setFretsMatrix,
    setFretsMatrixChordPattern,
    setFretsMatrixPatternNotes,
    setNumStrings,
    totalFrets,
  } = useContext(ChordsContext);

  const isVisible = selectedChordRoot !== 'undefined' && !!selectedChordId;

  const handleSelectChord = (chordId) => {
    setInversion(0);
    setSelectedChordId(chordId);
  };

  const navigate = useNavigate();
  const { root, chordId } = useParams();
  const [searchParams] = useSearchParams();

  const handleRootChange = (_, newNote) => {
    if (newNote !== null) setSelectedChordRoot(newNote);
  };

  useEffect(() => {
    if (!selectedChordId || !selectedChordRoot) return;

    const fetch = async () => {
      try {
        const params = {
          root: selectedChordRoot,
          chordId: selectedChordId,
          inversion,
          tuningId: selectedChordTuningId,
          startFret,
          totalFrets,
        };

        const { data } = await getChord(params);
        const {
          chord,
          notes,
          fretsMatrix,
          fretsMatrixChordPattern,
          fretsMatrixPatternNotes,
          numStrings,
        } = data;

        setChordInfo(chord);
        setActiveChordKeys(notes);
        setFretsMatrix(fretsMatrix);
        setFretsMatrixChordPattern(fretsMatrixChordPattern);
        setFretsMatrixPatternNotes(fretsMatrixPatternNotes);
        setNumStrings(numStrings);
      } catch (error) {
        console.error(error);
        toast.error('Cannot get chord');
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChordRoot, selectedChordId, inversion, selectedChordTuningId]);

  useEffect(() => {
    if (!selectedChordRoot || !selectedChordId) return;
    const url = `/chords/${selectedChordRoot}/${selectedChordId}?inversion=${inversion}&startFret=${startFret}&tuningId=${selectedChordTuningId}`;
    navigate(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChordRoot, selectedChordId, inversion, startFret, selectedChordTuningId]);

  useEffect(() => {
    const inversion = searchParams.get('inversion');
    const fret = searchParams.get('startFret');
    const tuningId = searchParams.get('tuningId');

    if (inversion) setInversion(Number(inversion));
    if (fret) setStartFret(Number(fret));
    if (tuningId) setSelectedChordTuningId(Number(tuningId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    setSelectedChordId(Number(chordId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chordId]);

  useEffect(() => {
    setSelectedChordRoot(root);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [root]);

  return (
    <Page>
      <LeftNavBar>
        <ChromaticNotes
          title="Root note"
          selectedRoot={selectedChordRoot}
          onChange={handleRootChange}
        />

        <ChordsList
          selectedChordId={selectedChordId}
          setSelectedChordId={setSelectedChordId}
          handleSelectChord={handleSelectChord}
        />

        <Tunings
          selectedTuningId={selectedChordTuningId}
          onSelect={setSelectedChordTuningId}
          onInfo={setChordTuningInfo}
          info={chordTuningInfo}
        />
      </LeftNavBar>

      {isVisible && <Outlet />}
    </Page>
  );
};

export default Chords;
