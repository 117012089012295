import { object, string } from 'yup';

const ChordFormValidation = () =>
  object().shape({
    name: string().required(),
    shortName: string().required(),
    pattern: string().required(),
  });

export default ChordFormValidation;
