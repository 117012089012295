import { Box, Button, Typography } from '@mui/material';
import { filetypes } from 'common/constants';
import { donwloadFile } from 'common/utils/download';
import { useState } from 'react';
import { toast } from 'react-toastify';
import ProgressionExportModal from './ProgressionExportModal';
import Section from './Section';

const Sections = ({
  sections,
  onMoveChord,
  onDeleteChord,
  onDelete,
  onMove,
  onInvertChord,
  onReplaceChord,
  onAdd,
  onClone,
}) => {
  const [openExportModal, setOpenExportModal] = useState(false);

  const sectionsToTxt = (sections) => {
    let text = '';

    sections.forEach((section) => {
      section.chordProgression.forEach((chord) => {
        text += `${chord.formattedName}(${chord.notes.join('-')})\t\t`;
      });
      text += '\n';
    });

    return text;
  };

  const handleExportProgression = async (filetype, filename) => {
    try {
      const now = Date.now();
      let data;
      const name = `${filename}.${filetype}` || `progression_${now}.${filetype}`;

      if (filetype === filetypes.json) {
        data = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(sections))}`;
        const link = document.createElement('a');
        link.href = data;
        link.download = name;
        link.click();
        link.remove();
      } else if (filetype === filetypes.txt) {
        data = sectionsToTxt(sections);
        donwloadFile(data, name);
      }
    } catch (error) {
      toast.error('Cannot export progression');
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Typography variant="h6">Sections ({sections.length})</Typography>

        <Button variant="outlined" onClick={() => setOpenExportModal(true)}>
          Export
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          flexDirection: 'column',
          paddingBottom: '1rem',
          marginTop: '0.5rem',
        }}
      >
        {sections.map((section, index) => (
          <Section
            section={section}
            index={index}
            onMoveChord={onMoveChord}
            onDeleteChord={onDeleteChord}
            onDelete={onDelete}
            onMove={onMove}
            onInvert={onInvertChord}
            onReplace={onReplaceChord}
            key={section.id}
            onAdd={onAdd}
            onClone={onClone}
          />
        ))}
      </Box>

      <ProgressionExportModal
        open={openExportModal}
        onClose={() => setOpenExportModal(false)}
        title="Export Chord Progression"
        onSubmit={handleExportProgression}
      />
    </>
  );
};

export default Sections;
