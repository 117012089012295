const { getDisplayNoteAccidental } = require('common/utils/utils');
const { useState, useEffect } = require('react');

const useGetNotesAccidental = (notes, accidental) => {
  const [newNotes, setNewNotes] = useState([]);

  useEffect(() => {
    if (notes?.length && accidental) {
      setNewNotes(notes.map((note) => getDisplayNoteAccidental(note, accidental)));
    }
  }, [notes, accidental]);

  return { newNotes };
};

export default useGetNotesAccidental;
