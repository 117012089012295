import { useContext } from 'react';
import PropTypes from 'prop-types';
import BlackKey from './BlackKey';
import { WHITE_KEY_WIDTH, BLACK_KEY_WIDTH, blackKeys } from 'common/constants';
import { Box } from '@mui/material';
import { getMidiNoteNumberFromName } from 'common/utils/utils';
import { KeyboardContext } from './KeyboardContext';

const BlackKeys = ({ activeKeys, octave }) => {
  const { setNotesToPlay, setPlay } = useContext(KeyboardContext);

  const handleClick = (note) => {
    const sharpNote = note.replace('♯', '#');
    const noteToPlay = getMidiNoteNumberFromName(`${sharpNote}${octave}`);
    setNotesToPlay([noteToPlay]);
    setPlay(true);
  };

  return (
    <Box sx={{ display: 'flex', height: '100%', position: 'absolute', top: 0 }}>
      {blackKeys.map(({ note, index, offset }) => {
        return (
          <BlackKey
            key={note}
            note={note}
            left={index * WHITE_KEY_WIDTH - BLACK_KEY_WIDTH + offset}
            isActive={activeKeys.some((activeKey) => note.includes(activeKey))}
            onClick={handleClick}
          />
        );
      })}
    </Box>
  );
};

BlackKeys.defaultProps = {
  activeKeys: [],
};

BlackKeys.props = {
  activeKeys: PropTypes.arrayOf(PropTypes.string),
};

export default BlackKeys;
