import axios from 'axios';
import conf from 'config';

const ApiClient = axios.create({
  baseURL: `${conf.baseUrl}/${conf.baseAPI}`,
  headers: { 'Content-Type': 'application/json' },
});

const handleError = (error) => {
  if (error.response.status === 401) {
    window.location.href = '/login';
  }
  throw error;
};

const interceptorsConf = (config) => {
  const token = localStorage.getItem('token');
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${token}`;
  return config;
};

ApiClient.interceptors.request.use(interceptorsConf);
ApiClient.interceptors.response.use(
  (response) => response,
  (error) => handleError(error)
);

export default ApiClient;
