import ApiClient from './ApiClient';

export const getScales = () => ApiClient.get('/scales');

export const addScale = (payload) => ApiClient.post('/scales', payload);

export const updateScale = (payload) => ApiClient.put('/scales', payload);

export const deleteScale = (id) => ApiClient.delete('/scales', { data: { id } });

export const getScale = (params) => ApiClient.get('/scales/scale', { params });

export const getScaleFrets = (params) => ApiClient.get('/scales/getScaleFrets', { params });
