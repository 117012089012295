import { Button, Box, Typography } from '@mui/material';
import CustomModal from 'components/CustomModal/CustomModal';

const DeleteModal = ({ open, onClose, title, submit, id, name }) => {
  return (
    <CustomModal open={open} onClose={onClose} title={title}>
      <Typography sx={{ marginTop: '1rem' }}>{`Please confirm: ${name} deletion`}</Typography>

      <Box
        sx={{
          display: 'flex',
          gap: '0.5rem',
          justifyContent: 'flex-end',
          marginTop: '1rem',
        }}
      >
        <Button variant="outlined" color="error" onClick={() => submit(id)}>
          Delete
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </CustomModal>
  );
};

export default DeleteModal;
