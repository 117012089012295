import { Box, Slider, Typography } from '@mui/material';

const FretsRange = ({ fretsRange, onChange, totalFrets }) => {
  return (
    <Box sx={{ width: 350, marginTop: 2 }}>
      <Typography>Frets Range ({fretsRange.join('-')})</Typography>
      <Slider
        value={fretsRange}
        onChange={onChange}
        valueLabelDisplay="auto"
        step={1}
        min={0}
        max={Number(totalFrets)}
        marks
      />
    </Box>
  );
};

export default FretsRange;
