import { accidentalEnarmonicNotes, whiteNotes } from 'common/constants';

const useGetActiveKeys = (notes) => {
  const whiteKeys = [];
  const blackKeys = [];

  notes.forEach((note) => {
    if (whiteNotes.includes(note)) whiteKeys.push(note);
    if (accidentalEnarmonicNotes.includes(note)) blackKeys.push(note);
  });

  return [whiteKeys, blackKeys];
};

export default useGetActiveKeys;
