import { useContext } from 'react';
import PropTypes from 'prop-types';
import WhiteKey from './WhiteKey';
import { whiteNotes } from 'common/constants';
import { Box } from '@mui/material';
import { KeyboardContext } from './KeyboardContext';
import { getMidiNoteNumberFromName } from 'common/utils/utils';

const WhiteKeys = ({ activeKeys, octave }) => {
  const { setNotesToPlay, setPlay } = useContext(KeyboardContext);

  const handleClick = (note) => {
    const noteToPlay = getMidiNoteNumberFromName(`${note}${octave}`);
    setNotesToPlay([noteToPlay]);
    setPlay(true);
  };

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      {whiteNotes.map((note) => (
        <WhiteKey
          key={note}
          note={note}
          isActive={activeKeys.includes(note)}
          onClick={handleClick}
        />
      ))}
    </Box>
  );
};

WhiteKeys.defaultProps = {
  activeKeys: [],
};

WhiteKeys.props = {
  activeKeys: PropTypes.arrayOf(PropTypes.string),
};

export default WhiteKeys;
