import axios from 'axios';
import conf from 'config';

const ApiClientBuffer = axios.create({
  baseURL: `${conf.baseUrl}/${conf.baseAPI}`,
  responseType: 'arraybuffer',
});

const handleError = (error) => {
  if (error.response.status === 401) {
    window.location.href = '/login';
  }
};

const interceptorsConf = (config) => {
  const token = localStorage.getItem('token');
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${token}`;
  return config;
};

ApiClientBuffer.interceptors.request.use(interceptorsConf);
ApiClientBuffer.interceptors.response.use(
  (response) => response,
  (error) => handleError(error)
);

export default ApiClientBuffer;
