import { useEffect, useState, useContext } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import ChordInversion from './ChordInversion';
import { ChordsContext } from 'store/ChordsProvider';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { getNotesToPlay } from 'common/utils/utils';
import { KeyboardContext } from 'components/Keyboard/KeyboardContext';

const ChordInfo = () => {
  const [chordNotes, setChordNotes] = useState('');
  const {
    activeChordKeys,
    selectedChordRoot,
    selectedChordId,
    chordInfo,
    inversion,
    setInversion,
  } = useContext(ChordsContext);

  const { setNotesToPlay, setPlay } = useContext(KeyboardContext);

  const formatShortName = `(${selectedChordRoot}${chordInfo.shortName})`;

  useEffect(() => {
    const tempNotes = Object.values(activeChordKeys).flat().join(' - ');
    setChordNotes(tempNotes);
  }, [activeChordKeys]);

  const handleChangeInversion = (_, newInversion) => {
    if (newInversion !== null) setInversion(newInversion);
  };

  const handlePlayClick = () => {
    if (!activeChordKeys) return;

    const notesToPlay = getNotesToPlay(activeChordKeys);
    setNotesToPlay(notesToPlay);
    setPlay(true);
  };

  return (
    <Box sx={{ display: 'flex', gap: '2rem' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          color: 'text.primary',
        }}
      >
        <Typography variant="h5">
          {selectedChordRoot} {chordInfo.name} {formatShortName}
          <IconButton onClick={handlePlayClick}>
            <PlayArrowIcon />
          </IconButton>
        </Typography>

        <Typography sx={{ color: 'text.primary' }}>({chordNotes})</Typography>
      </Box>

      <ChordInversion
        handleChangeInversion={handleChangeInversion}
        inversion={inversion}
        chordInfo={chordInfo}
        selectedChordId={selectedChordId}
        selectedChordRoot={selectedChordRoot}
      />
    </Box>
  );
};

export default ChordInfo;
