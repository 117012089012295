import { Box } from '@mui/material';
import useGetActiveKeys from 'common/hooks/useGetActiveKeys';
import BlackKeys from './BlackKeys';
import WhiteKeys from './WhiteKeys';

const Octave = ({ activeKeys, octave }) => {
  const [whiteActiveKeys, blackActiveKeys] = useGetActiveKeys(activeKeys);

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <WhiteKeys activeKeys={whiteActiveKeys} octave={octave} />
      <BlackKeys activeKeys={blackActiveKeys} octave={octave} />
    </Box>
  );
};

Octave.defaultProps = {
  activeKeys: [],
};

export default Octave;
