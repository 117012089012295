import { Divider, Typography } from '@mui/material';

const Header = ({ title }) => {
  return (
    <>
      <Typography
        variant='h5'
        sx={{ color: 'text.primary', padding: '0.5rem' }}
      >
        {title}
      </Typography>

      <Divider />
    </>
  );
};

export default Header;
