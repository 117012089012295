import Router from 'routes/router';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { Box } from '@mui/material';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const App = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <BrowserRouter>
        <Box
          sx={{
            backgroundColor: 'background.default',
            overflow: 'hidden',
            color: 'text.primary',
            height: '100vh',
          }}
        >
          <ToastContainer />
          <Router />
        </Box>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
