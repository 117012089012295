import Page from 'components/Page/Page';
import Header from 'components/Header/Header';

const Theory = () => {
  return (
    <Page>
      <Header title='Theory' />
    </Page>
  );
};

export default Theory;
