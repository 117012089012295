import { menuItems } from '../../routes/constants';
import { NavLink, useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { IconButton, Typography } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

const Navbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1rem',
        padding: '0.5rem',
        alignItems: 'center',
      }}
    >
      <Box sx={{ display: 'flex', flex: 1, gap: '0.5rem' }}>
        {menuItems.map((item) => (
          <div key={item.name}>
            <NavLink
              to={item.path}
              style={({ isActive }) => ({
                color: isActive ? 'lightBlue' : 'white',
              })}
            >
              <Typography variant="h6">{item.name}</Typography>
            </NavLink>
          </div>
        ))}
      </Box>

      <IconButton onClick={handleLogout}>
        <PowerSettingsNewIcon />
      </IconButton>
    </Box>
  );
};

export default Navbar;
