import { WHITE_KEY_WIDTH } from 'common/constants';
import { Button, Typography } from '@mui/material';

const WhiteKey = ({ note, isActive, onClick }) => {
  const handleClick = () => onClick(note);

  return (
    <Button
      onClick={handleClick}
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        backgroundColor: isActive ? 'green' : 'white',
        height: '100%',
        width: `${WHITE_KEY_WIDTH}px`,
        minWidth: `${WHITE_KEY_WIDTH}px`,
        borderRadius: '0.1rem',
        marginRight: '1px',
        '&:hover': {
          backgroundColor: 'gray',
        },
      }}
    >
      <Typography>{note}</Typography>
    </Button>
  );
};

export default WhiteKey;
