export const menuItems = [
  { name: 'Projects', path: 'projects' },
  { name: 'Chords', path: 'chords' },
  { name: 'Scales', path: 'scales' },
  { name: 'Progression', path: 'chord-progression' },
  { name: 'Melody Gen', path: 'melody-generator' },
  { name: 'Harmony', path: 'harmony' },
  { name: 'Rhythm', path: 'rhythm' },
  { name: 'Theory', path: 'theory' },
];
