import { Box } from '@mui/material';

const Page = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1rem',
        flex: '1 1 auto',
        overflow: 'auto',
      }}
    >
      {children}
    </Box>
  );
};

export default Page;
