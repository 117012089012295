import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { getMelody } from 'api/melodyGenerator';
import { donwloadFile } from 'common/utils/download';
import { toast } from 'react-toastify';

const MelodyGenerator = () => {
  const handleClick = async () => {
    try {
      const res = await getMelody();
      donwloadFile(res);
    } catch (error) {
      console.error(error);
      toast.error('Melody download error');
    }
  };

  return (
    <Box sx={{ marginTop: '2rem', marginLeft: '1rem' }}>
      <Button variant="contained" onClick={handleClick}>
        Get Melody
      </Button>
    </Box>
  );
};

export default MelodyGenerator;
