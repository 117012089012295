import { useState, useContext } from 'react';
import {
  Box,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Stack,
  MenuItem,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ReplayIcon from '@mui/icons-material/Replay';
import DeleteModal from 'components/DeleteModal/DeleteModal';
import { toast } from 'react-toastify';
import { ScalesContext } from 'store/ScalesProvider';
import { addScale, deleteScale, updateScale } from 'api/scalesAPI';
import { AppContext } from 'store/AppProvider';
import ScaleModal from 'components/ScaleModal/ScaleModal';

const ScalesList = () => {
  const [search, setSearch] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openScaleModal, setOpenScaleModal] = useState(false);
  const { scales } = useContext(AppContext);
  const { handleSelectScale, scaleInfo, selectedScaleId, setSelectedScaleId } =
    useContext(ScalesContext);
  const { setReloadScales } = useContext(AppContext);

  const formatScaleLabel = (scale) => `${scale.name}`;

  const canEdit = !!selectedScaleId;

  const filteredScales = scales.filter((scale) => scale.name.toLowerCase().includes(search));

  const handleDisplayAdd = () => {
    setIsEdit(false);
    setOpenScaleModal(true);
  };

  const handleDisplayDelete = () => {
    if (!canEdit) return;
    setOpenDeleteModal(true);
  };

  const handleDisplayEdit = () => {
    if (!canEdit) return;
    setIsEdit(true);
    setOpenScaleModal(true);
  };

  const handleDeleteScale = async (id) => {
    try {
      await deleteScale(id);
      toast.success('Scale deleted');
      setSelectedScaleId('');
      setReloadScales(true);
    } catch (error) {
      console.error(error);
      toast.error('Cannot delete scale');
    }
    setOpenDeleteModal(false);
  };

  const handleAddScale = async (data) => {
    try {
      await addScale(data);
      toast.success('Scale added');
      setReloadScales(true);
    } catch (error) {
      console.error(error);
      toast.error('Cannot add scale');
    }
    setOpenScaleModal(false);
  };

  const handleEditScale = async (data) => {
    try {
      await updateScale(data);
      toast.success('Scale updated');
      setReloadScales(true);
    } catch (error) {
      console.error(error);
      toast.error('Cannot edit scale');
    }
    setOpenScaleModal(false);
  };

  const handleScaleClick = (scale) => {
    handleSelectScale(scale.id);
  };

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <Typography sx={{ color: 'text.primary' }}>{`Scales (${scales.length})`}</Typography>

        <IconButton onClick={handleDisplayAdd}>
          <AddIcon />
        </IconButton>

        <IconButton onClick={handleDisplayEdit} disabled={!canEdit}>
          <EditIcon />
        </IconButton>

        <IconButton onClick={handleDisplayDelete} disabled={!canEdit}>
          <DeleteIcon />
        </IconButton>
      </Box>

      <TextField
        fullWidth
        placeholder="Search scale"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setSearch('');
                }}
                edge="end"
              >
                <ReplayIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />

      <Stack
        sx={{
          overflowY: 'auto',
          height: '300px',
          flex: '1 1 auto',
          border: '1px solid',
          borderColor: 'divider',
        }}
      >
        {filteredScales.map((scale) => (
          <MenuItem
            key={scale.name}
            value={scale.id}
            sx={{
              backgroundColor: scale.id === selectedScaleId ? 'green' : '',
            }}
            onClick={() => handleScaleClick(scale)}
          >
            {formatScaleLabel(scale)}
          </MenuItem>
        ))}
      </Stack>

      <ScaleModal
        open={openScaleModal}
        onClose={() => setOpenScaleModal(false)}
        title={isEdit ? 'Edit Scale' : 'Add Scale'}
        submit={isEdit ? handleEditScale : handleAddScale}
        isEdit={isEdit}
        scale={isEdit ? scaleInfo : null}
      />

      <DeleteModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title="Delete scale"
        submit={handleDeleteScale}
        id={scaleInfo?.id}
        name={scaleInfo?.name}
      />
    </div>
  );
};

export default ScalesList;
