import { useEffect, Fragment, useState } from 'react';
import { fretboard, MAX_FRETS } from 'common/constants';
import { getRandomString } from 'common/utils/utils';
import Tablature from '../Tablature/Tablature';
import { Box, TextField } from '@mui/material';
import FretsRange from './FretsRange';
import { toast } from 'react-toastify';
import { getScaleFrets } from 'api/scalesAPI';
import { useContext } from 'react';
import { ScalesContext } from 'store/ScalesProvider';
import AccidentalSwitch from 'components/AccidentalSwitch/AccidentalSwitch';

const Fretboard = () => {
  const [fretNotes, setFretNotes] = useState([]);
  const [fretsMatrix, setFretsMatrix] = useState([]);
  const [fretsRange, setFretsRange] = useState([0, MAX_FRETS]);
  const [fretNumbers, setFretNumbers] = useState(fretboard);
  const [filteredFretNotes, setFilteredFretNotes] = useState([]);
  const [filteredScaleFretsMatrix, setfilteredScaleFretsMatrix] = useState([]);

  const {
    scaleTuningInfo,
    selectedScaleRoot,
    totalFrets,
    setTotalFrets,
    startFretDisplay,
    setStartFretDisplay,
    setEndFretDisplay,
    accidental,
    setAccidental,
    scaleTransposedPattern,
    selectedScaleTuningId,
    selectedScaleId,
  } = useContext(ScalesContext);

  const numStrings = scaleTuningInfo.pattern.length;

  const styleFretboard = (fretsCount, stringsCount) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${fretsCount}, 40px)`,
    gridTemplateRows: `repeat(${stringsCount}, auto)`,
    fontSize: '1.2rem',
    textAlign: 'center',
  });

  const isActiveStyle = (isRoot) => ({
    color: 'white',
    backgroundColor: isRoot ? 'green' : 'blue',
    borderRadius: '50%',
  });

  const fretStyle = (isLastString) => ({
    border: isLastString ? 'none' : '1px solid gray',
    padding: '0.8rem 0.3rem',
  });

  const fretNoteStyle = {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    marginTop: '-29px',
    maxWidth: '40px',
  };

  const fretNumber = {
    marginBottom: '1rem',
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const params = {
          root: selectedScaleRoot,
          tuningId: selectedScaleTuningId,
          scaleId: selectedScaleId,
          accidental,
        };

        const { data } = await getScaleFrets(params);
        const { notes, scaleFretsMatrix } = data;
        setFretNotes(notes);
        setFilteredFretNotes(notes);
        setFretsMatrix(scaleFretsMatrix);
        setfilteredScaleFretsMatrix(scaleFretsMatrix);
      } catch (error) {
        console.error(error);
        toast.error('Cannot get frets data');
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScaleTuningId, selectedScaleRoot, accidental, selectedScaleId]);

  useEffect(() => {
    const newFretNumbers = fretboard.slice(0, Number(totalFrets) + 1);
    const newFretNotes = fretNotes.map((stringNotes) =>
      stringNotes.slice(0, Number(totalFrets) + 1)
    );
    const newScaleFretsMatrix = fretsMatrix.map((stringNotes) =>
      stringNotes.slice(0, Number(totalFrets) + 1)
    );

    setFretNumbers(newFretNumbers);
    setFilteredFretNotes(newFretNotes);
    setfilteredScaleFretsMatrix(newScaleFretsMatrix);
  }, [fretNotes, fretsMatrix, totalFrets]);

  const handleChangeFretsRange = (_, newValue) => {
    setFretsRange(newValue);
    const [start, end] = newValue;
    setStartFretDisplay(start);
    setEndFretDisplay(end);

    const newFretNumbers = fretboard.slice(Number(start), Number(end) + 1);
    const newFretNotes = fretNotes.map((stringNotes) =>
      stringNotes.slice(Number(start), Number(end) + 1)
    );
    const newScaleFretsMatrix = fretsMatrix.map((stringNotes) =>
      stringNotes.slice(Number(start), Number(end) + 1)
    );

    setFretNumbers(newFretNumbers);
    setFilteredFretNotes(newFretNotes);
    setfilteredScaleFretsMatrix(newScaleFretsMatrix);
  };

  const handleTotalFretsChange = (event) => {
    const value = event.target.value;
    setTotalFrets(value);

    const newFretsRange = [...fretsRange];
    newFretsRange[1] = Number(value);
    setFretsRange(newFretsRange);

    setEndFretDisplay(value);
  };

  const handleAccidentalChange = (event) => {
    setAccidental(event.target.value);
  };

  return (
    <>
      <Box sx={{ display: 'flex', gap: '1.5rem', alignItems: 'center' }}>
        <TextField
          type="number"
          label="Frets"
          inputProps={{ min: 0, max: 24 }}
          sx={{ width: 80 }}
          value={totalFrets}
          onChange={handleTotalFretsChange}
        />

        <FretsRange
          fretsRange={fretsRange}
          onChange={handleChangeFretsRange}
          totalFrets={totalFrets}
        />

        <AccidentalSwitch accidental={accidental} onChange={handleAccidentalChange} />
      </Box>

      <div style={styleFretboard(fretNumbers.length, numStrings)}>
        {fretNumbers.map((fret) => (
          <div key={fret} style={fretNumber}>
            {fret}
          </div>
        ))}

        {filteredFretNotes.map((stringNotes, stringIndex) => (
          <Fragment key={getRandomString()}>
            {stringNotes.map((note) => {
              const activeStyle = note.isActive ? isActiveStyle(note.isRoot) : {};
              const isLastString = numStrings === stringIndex + 1;
              return (
                <div key={getRandomString()} style={fretStyle(isLastString)}>
                  <span style={{ ...fretNoteStyle, ...activeStyle }}>{note.value}</span>
                </div>
              );
            })}
          </Fragment>
        ))}
      </div>

      <Tablature
        fretsMatrix={filteredScaleFretsMatrix}
        pattern={scaleTransposedPattern}
        startFret={startFretDisplay}
        tuning={scaleTuningInfo.pattern}
      />
    </>
  );
};

export default Fretboard;
