import { useState, useContext, useEffect } from 'react';
import { Box, IconButton, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ReplayIcon from '@mui/icons-material/Replay';
import { Stack } from '@mui/system';
import DeleteModal from 'components/DeleteModal/DeleteModal';
import { toast } from 'react-toastify';
import ChordModal from '../../pages/Chords/Common/ChordModal';
import { addChord, deleteChord, updateChord } from 'api/chordsAPI';
import { AppContext } from 'store/AppProvider';
import { ChordsContext } from 'store/ChordsProvider';

const ChordsList = ({ selectedChordId, handleSelectChord, setSelectedChordId }) => {
  const [search, setSearch] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openChordModal, setOpenChordModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { chords, setReloadChords } = useContext(AppContext);
  const [filteredChords, setFilteredChords] = useState([]);
  const formatChordLabel = (chord) => `${chord.name} (${chord.shortName})`;

  const { chordInfo } = useContext(ChordsContext);

  const canEdit = !!selectedChordId;

  useEffect(() => {
    const filtered = chords.filter((chord) => chord.name.toLowerCase().includes(search));
    setFilteredChords(filtered);
  }, [chords, search]);

  const handleDisplayAdd = () => {
    setIsEdit(false);
    setOpenChordModal(true);
  };

  const handleDisplayDelete = () => {
    if (!canEdit) return;
    setOpenDeleteModal(true);
  };

  const handleDisplayEdit = () => {
    if (!canEdit) return;
    setIsEdit(true);
    setOpenChordModal(true);
  };

  const handleCloseModal = () => {
    setOpenChordModal(false);
  };

  const handleAddChord = async (data) => {
    try {
      await addChord(data);
      toast.success('Chord added');
      setReloadChords(true);
    } catch (error) {
      console.error(error);
      toast.error('Cannot add chord');
    }
    setOpenChordModal(false);
  };

  const handleEditChord = async (data) => {
    try {
      await updateChord(data);
      toast.success('Chord updated');
      setReloadChords(true);
    } catch (error) {
      console.error(error);
      toast.error('Cannot edit chord');
    }
    setOpenChordModal(false);
  };

  const handleDeleteChord = async (id) => {
    try {
      await deleteChord(id);
      toast.success('Chord deleted');
      setSelectedChordId('');
      setReloadChords(true);
    } catch (error) {
      console.error(error);
      toast.error('Cannot delete chord');
    }
    setOpenDeleteModal(false);
  };

  const handleChordClick = (chord) => {
    handleSelectChord(chord.id);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <Typography sx={{ color: 'text.primary' }}>{`Chords (${chords.length})`}</Typography>

        <IconButton onClick={handleDisplayAdd}>
          <AddIcon />
        </IconButton>

        <IconButton onClick={handleDisplayEdit} disabled={!canEdit}>
          <EditIcon />
        </IconButton>

        <IconButton onClick={handleDisplayDelete} disabled={!canEdit}>
          <DeleteIcon />
        </IconButton>
      </Box>

      <TextField
        fullWidth
        placeholder="Search chord"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setSearch('');
                }}
                edge="end"
              >
                <ReplayIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />

      <Stack
        sx={{
          overflowY: 'auto',
          height: '100px',
          flex: '1 1 auto',
          border: '1px solid',
          borderColor: 'divider',
        }}
      >
        {filteredChords.map((chord) => (
          <MenuItem
            key={chord.name}
            value={chord.id}
            sx={{
              backgroundColor: chord.id === selectedChordId ? 'green' : '',
            }}
            onClick={() => handleChordClick(chord)}
          >
            {formatChordLabel(chord)}
          </MenuItem>
        ))}
      </Stack>

      <ChordModal
        open={openChordModal}
        onClose={handleCloseModal}
        title={isEdit ? 'Edit chord' : 'Add Chord'}
        submit={isEdit ? handleEditChord : handleAddChord}
        isEdit={isEdit}
        chord={isEdit ? chordInfo : null}
      />

      <DeleteModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title="Delete chord"
        submit={handleDeleteChord}
        id={chordInfo?.id}
        name={chordInfo?.name}
      />
    </>
  );
};

export default ChordsList;
