import { Button, Box } from '@mui/material';
import { transposeNote } from 'common/utils/utils';
import CustomModal from 'components/CustomModal/CustomModal';
import { useState, useRef } from 'react';
import ScaleForm from 'components/ScaleForm/ScaleForm';

const ScaleModal = ({ open, onClose, title, submit, isEdit, scale }) => {
  const formRef = useRef();
  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const handleSubmit = async (data) => {
    const { pattern } = data;
    const patternArray = pattern.split(',');
    const [root] = patternArray;
    const transposedPattern = patternArray.map((note) => transposeNote(note, root));

    const payload = {
      ...data,
      pattern: transposedPattern.toString(),
    };
    await submit(payload);
    onClose();
  };

  const normalizeData = (data) => {
    if (!isEdit) return;
    const normalized = {
      id: data.id,
      name: data.name,
      pattern: data.pattern.toString(),
    };

    return normalized;
  };

  return (
    <CustomModal open={open} onClose={onClose} title={title}>
      <ScaleForm
        onSubmit={handleSubmit}
        defaultValues={normalizeData(scale)}
        formRef={formRef}
        setIsDirty={setIsDirty}
        setIsValid={setIsValid}
      />

      <Box
        sx={{
          display: 'flex',
          gap: '0.5rem',
          justifyContent: 'flex-end',
          marginTop: '1rem',
        }}
      >
        <Button
          variant="outlined"
          color="success"
          disabled={!isValid || !isDirty}
          onClick={() => {
            formRef.current?.dispatchEvent(
              new Event('submit', { cancelable: true, bubbles: true })
            );
          }}
        >
          {isEdit ? 'Edit' : 'Add'}
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </CustomModal>
  );
};

export default ScaleModal;
