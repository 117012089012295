import { useState, useRef, useEffect, useContext } from 'react';
import { Box, Button, FormControlLabel, FormGroup, IconButton, Switch } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuid } from 'uuid';
import Chord from './Chord';
import { grey } from '@mui/material/colors';
import Sections from './Sections/Sections';
import AddChordModal from './AddChordModal';
import { toast } from 'react-toastify';
import { getProgressionChord } from 'api/ApiChordProgression';
import AccidentalSwitch from 'components/AccidentalSwitch/AccidentalSwitch';
import useGetNotesAccidental from 'common/hooks/useGetNotesAccidental';
import { ProgressionScalesContext } from 'store/ProgressionScalesProvider';
import { getDisplayNoteAccidental } from 'common/utils/utils';

const DisplayChordProgression = ({ data, startWithRoot, setStartWithRoot }) => {
  const { scale } = data;
  const { name, notes } = scale;
  const [openAddChord, setOpenAddChord] = useState(false);
  const [sections, setSections] = useState([]);
  const [currentChords, setCurrentChords] = useState([]);
  const { accidental, setAccidental } = useContext(ProgressionScalesContext);
  const { newNotes } = useGetNotesAccidental(notes, accidental);
  const fileInputRef = useRef();

  useEffect(() => {
    const { chordProgression } = data;
    setCurrentChords(chordProgression);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, accidental]);

  const handleAddSection = () => {
    const newId = uuid();
    const newSection = {
      id: newId,
      chordProgression: JSON.parse(JSON.stringify(currentChords)),
    };

    const newSections = [...sections];
    newSections.push(newSection);
    setSections(newSections);
  };

  const handleDeleteSection = (id) => {
    const newSections = sections.filter((section) => section.id !== id);
    setSections(newSections);
  };

  const moveChord = (dragIndex, hoverIndex) => {
    const temp = [...currentChords];
    [temp[hoverIndex], temp[dragIndex]] = [temp[dragIndex], temp[hoverIndex]];
    setCurrentChords(temp);
  };

  const moveChordInSection = (dragIndex, hoverIndex, sectionId) => {
    const newSections = [...sections];
    const targetSectionIndex = newSections.map((section) => section.id).indexOf(sectionId);
    const { chordProgression: targetProgression } = newSections[targetSectionIndex];
    [targetProgression[hoverIndex], targetProgression[dragIndex]] = [
      targetProgression[dragIndex],
      targetProgression[hoverIndex],
    ];
    setSections(newSections);
  };

  const deleteChordInSection = (chordIndex, sectionId) => {
    const newSections = [...sections];
    const targetSectionIndex = newSections.map((section) => section.id).indexOf(sectionId);
    newSections[targetSectionIndex].chordProgression.splice(chordIndex, 1);
    setSections(newSections);
  };

  const deleteChord = (index) => {
    const newChords = [...currentChords];
    newChords.splice(index, 1);
    setCurrentChords(newChords);
  };

  const handleChangeStartRoot = (event) => {
    setStartWithRoot(event.target.checked);
  };

  const moveSection = (dragIndex, hoverIndex) => {
    const temp = [...sections];
    [temp[hoverIndex], temp[dragIndex]] = [temp[dragIndex], temp[hoverIndex]];
    setSections(temp);
  };

  const handleAddChord = async (chordId, root) => {
    try {
      const [scaleRoot] = notes;
      const params = {
        root,
        chordId,
        scaleRoot,
      };

      const { data } = await getProgressionChord(params);
      const { chord } = data;

      const temp = [...currentChords];
      temp.push(chord);
      setCurrentChords(temp);
    } catch (error) {
      console.error(error);
      toast.error('Cannot add chord');
    }
  };

  const handleReplaceChord = async (chordId, root, index) => {
    try {
      const [scaleRoot] = notes;
      const params = {
        root,
        chordId,
        scaleRoot,
      };

      const { data } = await getProgressionChord(params);
      const { chord } = data;
      const temp = [...currentChords];
      temp.splice(index, 1, chord);
      setCurrentChords(temp);
    } catch (error) {
      console.error(error);
      toast.error('Cannot replace chord');
    }
  };

  const handleReplaceChordInSection = async (chordId, root, index, sectionId) => {
    try {
      const newSections = [...sections];
      const [targetSection] = newSections.filter((section) => section.id === sectionId);
      const targetChord = targetSection.chordProgression[index];

      const [scaleRoot] = targetChord.notes;
      const params = {
        root,
        chordId,
        scaleRoot,
      };

      const { data } = await getProgressionChord(params);
      const { chord } = data;

      targetSection.chordProgression.splice(index, 1, chord);
      setSections(newSections);
    } catch (error) {
      console.error(error);
      toast.error('Cannot replace chord');
    }
  };

  const handleInvertChord = (index, sectionId) => {
    let tempChords;
    let newSections;

    if (sectionId) {
      newSections = [...sections];
      const targetSectionIndex = newSections.map((section) => section.id).indexOf(sectionId);
      const { chordProgression: targetProgression } = newSections[targetSectionIndex];
      tempChords = [...targetProgression];
    } else {
      tempChords = [...currentChords];
    }

    const selectedChord = tempChords[index];
    const { notes } = selectedChord;

    const [rootNote] = notes;
    selectedChord.notes.push(rootNote);
    selectedChord.notes.shift();

    let invertedChordName;
    const [bassNote] = selectedChord.notes;

    if (selectedChord.notes[0] === selectedChord.root) {
      invertedChordName = `${getDisplayNoteAccidental(selectedChord.root, accidental)}${
        selectedChord.shortName
      }`;
    } else {
      invertedChordName = `${getDisplayNoteAccidental(selectedChord.root, accidental)}${
        selectedChord.shortName
      }/${getDisplayNoteAccidental(bassNote, accidental)}`;
    }

    selectedChord.formattedName = invertedChordName;

    if (sectionId) {
      setSections(newSections);
    } else {
      setCurrentChords(tempChords);
    }
  };

  const handleAddChordInSection = async (chordId, root, sectionId) => {
    try {
      const newSections = [...sections];
      const [scaleRoot] = notes;

      const params = {
        root,
        chordId,
        scaleRoot,
      };

      const { data } = await getProgressionChord(params);
      const { chord } = data;

      const [selectedSection] = newSections.filter((section) => section.id === sectionId);

      selectedSection.chordProgression.push(chord);

      setSections(newSections);
    } catch (error) {
      console.error(error);
      toast.error('Cannot add chord');
    }
  };

  const handleImportProgression = () => {
    fileInputRef.current.click();
  };

  const loadSectionsFromFile = (event) => {
    const [file] = event.target.files;

    const reader = new FileReader();
    reader.onload = () => {
      const data = reader.result;
      const sectionsJson = JSON.parse(data);
      setSections(sectionsJson);
    };
    reader.readAsText(file);
  };

  const handleCloneSection = (sectionId) => {
    const newSections = [...sections];
    const sourceSectionIndex = newSections.findIndex((section) => section.id === sectionId);

    const sourceSection = newSections[sourceSectionIndex];
    const newSection = JSON.parse(JSON.stringify(sourceSection));
    newSection.id = uuid();

    newSections.splice(sourceSectionIndex + 1, 0, newSection);

    setSections(newSections);
  };

  const handleAccidentalChange = (event) => {
    setAccidental(event.target.value);
  };

  return (
    <>
      <Box>
        <p>Scale: {name}</p>
        <p>{newNotes.join(' - ')}</p>

        <AccidentalSwitch accidental={accidental} onChange={handleAccidentalChange} />

        <Box>
          <FormGroup sx={{ display: 'flex', flexDirection: 'row', marginBottom: '0.5rem' }}>
            <FormControlLabel
              control={
                <IconButton onClick={handleAddSection}>
                  <AddIcon />
                </IconButton>
              }
              label="Add Section"
            />

            <FormControlLabel
              control={<Switch onChange={handleChangeStartRoot} checked={startWithRoot} />}
              label="Start with root"
            />

            <FormControlLabel
              control={
                <IconButton onClick={() => setOpenAddChord(true)}>
                  <AddIcon />
                </IconButton>
              }
              label="Add Chord"
            />

            <Button variant="outlined" onClick={handleImportProgression}>
              Import
              <input
                ref={fileInputRef}
                type="file"
                accept=".json"
                hidden
                onChange={loadSectionsFromFile}
              />
            </Button>
          </FormGroup>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            marginBottom: '1rem',
            backgroundColor: grey[800],
            padding: '0.5rem',
          }}
        >
          {currentChords.map((chord, index) => (
            <Chord
              chord={chord}
              key={uuid()}
              index={index}
              moveChord={moveChord}
              deleteChord={deleteChord}
              onReplaceChord={handleReplaceChord}
              onInvertChord={handleInvertChord}
            />
          ))}
        </Box>

        {sections.length > 0 && (
          <Sections
            sections={sections}
            onMove={moveSection}
            onDelete={handleDeleteSection}
            onMoveChord={moveChordInSection}
            onDeleteChord={deleteChordInSection}
            onReplaceChord={handleReplaceChordInSection}
            onInvertChord={handleInvertChord}
            onAdd={handleAddChordInSection}
            onClone={handleCloneSection}
          />
        )}
      </Box>

      <AddChordModal
        open={openAddChord}
        onClose={() => setOpenAddChord(false)}
        title="Add Chord"
        submit={handleAddChord}
      />
    </>
  );
};

export default DisplayChordProgression;
