import { useEffect } from 'react';
import { Box } from '@mui/system';
import MidiPlayer from 'components/MidiPlayer/MidiPlayer';
import Octave from './Octave';
import { getNotesToPlay } from 'common/utils/utils';
import { KeyboardContext } from './KeyboardContext';
import { useContext } from 'react';

const Keyboard = ({ activeKeys, isPlayChord, isPlayScale }) => {
  const { notesToPlay, setNotesToPlay, play, setPlay } = useContext(KeyboardContext);

  useEffect(() => {
    const notes = getNotesToPlay(activeKeys);
    setNotesToPlay(notes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKeys]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <MidiPlayer
        notes={notesToPlay}
        isChord={isPlayChord}
        isScale={isPlayScale}
        isPlay={play}
        setIsPlay={setPlay}
      />

      <Box
        sx={{
          display: 'flex',
          height: '200px',
          width: 'fit-content',
        }}
      >
        <Octave activeKeys={activeKeys?.octave1} octave="4" />
        <Octave activeKeys={activeKeys?.octave2} octave="5" />
        <Octave activeKeys={activeKeys?.octave3} octave="6" />
      </Box>
    </Box>
  );
};

Keyboard.defaultProps = {
  activeKeys: {},
};

export default Keyboard;
