import { Box, Divider } from '@mui/material';
import Navbar from 'components/Navbar/Navbar';
import { Outlet } from 'react-router-dom';
import { AppProvider } from 'store/AppProvider';
import { ScalesProvider } from 'store/ScalesProvider';
import { KeyboardProvider } from 'components/Keyboard/KeyboardContext';
import { ProgressionScalesProvider } from 'store/ProgressionScalesProvider';
import { ChordsProvider } from 'store/ChordsProvider';
import 'react-toastify/dist/ReactToastify.css';

const Home = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <AppProvider>
        <ChordsProvider>
          <ScalesProvider>
            <ProgressionScalesProvider>
              <KeyboardProvider>
                <Navbar />
                <Divider />
                <Outlet />
              </KeyboardProvider>
            </ProgressionScalesProvider>
          </ScalesProvider>
        </ChordsProvider>
      </AppProvider>
    </Box>
  );
};

export default Home;
